import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import MissingRemittancesHistoryForm from "./MissingRemittancesHistoryForm";
import MissingRemittanceDownloadAttachment from "./MissingRemittanceDownloadAttachment.js";
import MissingRemittanceStatusHistoryModal from "./MissingRemittanceStatusHistoryModal";

import { Row, Col } from "react-bootstrap";
import Accordion from "../../Accordion";
import { GridColumn } from "@progress/kendo-react-grid";
import SharedMainGrid from "../../grid/Kendo/SharedMainGrid";
import { constants } from "./MissingRemittanceActiveConstants";
import { LoadingSpinner } from "../../layout/LoadingSpinner";

import { GetDataWithCleanedDates } from "../../../helpers/DateHelpers.js";

export function MissingRemittancesHistory(props) {
  const { passedProps } = props;
  const { statusUpdated } = passedProps;

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});

  const [submittedFormData, setSubmittedFormData] = useState(null);

  const [gridData, setGridData] = useState([]);

  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [detailsPaymentId, setDetailsPaymentId] = useState(null);

  const navigate = useNavigate();
  const [noGridAggregates, setNoGridAggregates] = useState([]);
  const sendAggregatesToParent = (aggregatesFromSharedMain) => {
    setNoGridAggregates(aggregatesFromSharedMain.data);
  };

  //create a comma separated list of statues that will initial a link to remittance management screen
  const [remittanceManagementStatuses] = useState(
    "Manually Received,Automatically Received"
  );

  //create a dictionary of statuses that will initial a link to remittance management screen, so it will be very fast to check if the status is in the list
  const remittanceManagementStatusesDictionary = remittanceManagementStatuses
    .split(",")
    .reduce((acc, curr) => {
      acc[curr] = true;
      return acc;
    }, {});

  useEffect(() => {
    if (!submittedFormData || !submittedFormData.locations) return;

    getMainGridData();
  }, [submittedFormData, statusUpdated]);

  function getMainGridData() {
    try {
      setIsLoading(true);

      let accessToken = passedProps.auth.getAccessToken();

      let parameters = {
        locations: submittedFormData.locations,
        searchcriteria: submittedFormData.searchText,
        startdate: submittedFormData.fromDate,
        enddate: submittedFormData.toDate,
      };

      axios
        .get("api/MissingRemittances/GetHistoryData", {
          params: parameters,
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then(onSuccess)
        .catch(onFailure);
    } catch (exception) {
      onFailure(exception);
    }
  }

  function onSuccess(response) {
    //alert("onSuccess submittedFormData " + submittedFormData.locations);

    let columns = [];
    let data = response.data;

    //console.log(data);

    if (data.length > 0) {
      const headerProps = constants.headerProps;

      //console.log("headerProps", headerProps);

      Object.keys(data[0]).map((key) => {
        //alert("key " + key);
        //alert("key " + key + " headerProps[key] " + headerProps[key]);

        if (headerProps[key] && headerProps[key].title) {
          return columns.push({
            field: key,
            title: headerProps[key].title,
            type: headerProps[key].type,
            fixedLeft: headerProps[key].fixedLeft,
            hidden: headerProps[key].hidden,
            showTotal: headerProps[key].showTotal,
            truncatable: headerProps[key]
              ? headerProps[key].truncatable
              : false,
            isLocked: headerProps[key].isLocked,
          });
        }
      });
      setError({});
    } else {
      setError({ status: 201, Message: "No data found for given parameters" });
    }

    // let dataWithCleanedDates = response.data.map((t) => {
    //   return {
    //     ...t,
    //     requestDate: IsNullOrWhitespace(t.requestDate)
    //       ? ""
    //       : new Date(Date.parse(t.requestDate)),

    //     checkDate: IsNullOrWhitespace(t.checkDate)
    //       ? ""
    //       : new Date(Date.parse(t.checkDate)),
    //   };
    // });

    let dataWithCleanedDates = GetDataWithCleanedDates(response.data);
    // alert("cleaned")

    //setGridColumns(columns);
    setGridData(dataWithCleanedDates);

    //console.log("dataWithCleanedDates", dataWithCleanedDates);
    setIsLoading(false);
  }

  function onFailure(error) {
    setIsLoading(false);

    //console.log(error);
  }

  //Will bring up the modal with the history of the status of the missing remittance
  const StatusHistoryActionCell = (props) => {
    async function viewClick() {
      setDetailsPaymentId(props.dataItem["paymentId"]);

      setShowDetailsModal(true);
    }

    return (
      <td className="missingRemittanceStatusColumn" style={{ ...props.style }}>
        <a
          onClick={viewClick}
          style={{
            cursor: "pointer",
            textDecoration: "underline",
            color: "#015CAB",
          }}
        >
          {props.dataItem["status"]}
        </a>
      </td>
    );
  };

  const AttachmentDownloadCell = (props) => {
    return (
      <td className="noWrap centered " style={{ ...props.style }}>
        {props.dataItem["hasAttachment"] && (
          <span
            style={{ cursor: "pointer" }}
            onClick={() =>
              MissingRemittanceDownloadAttachment(
                props.dataItem["paymentId"],
                props.dataItem["attachedFileName"],
                passedProps.auth
              )
            }
          >
            <i class="fa fa-download" aria-hidden="true"></i>{" "}
          </span>
        )}
      </td>
    );
  };

  const onLinkedCheckNumberClick = async (dataItem) => {
    // navigate("/RemitLookUp", {
    //   state: {
    //     linkedCheckNumber: dataItem["checkNumber"],
    //     linkedCheckDate: dataItem["checkDate"],
    //     linkedCheckAmount: dataItem["checkAmount"],
    //   },
    // });
    navigate("/RemitLookUp?pmid=" + dataItem.pmidOfReceivedRemittance, {});
  };

  //Netx-2437 if the status is in the list of statuses that will initial a link to remittance management screen, then the check number will be a link to the remittance management screen
  const LinkToRemittanceDetailActionCell = (props) => {
    //check if the status is in the list of statuses that will initial a link to remittance management screen
    let isRemittanceManagementStatus =
      remittanceManagementStatusesDictionary[props.dataItem["status"]];

    //if the status is in the list of statuses show the check number as a link, otherwise just show the check number
    if (
      isRemittanceManagementStatus &&
      props.dataItem.pmidOfReceivedRemittance
    ) {
      return (
        <td {...props.style}>
          <a
            onClick={() => onLinkedCheckNumberClick(props.dataItem)}
            className="missingRemittancePageLink"
          >
            {props.dataItem["checkNumber"]}
          </a>
        </td>
      );
    } else {
      return <td {...props.style}>{props.dataItem["checkNumber"]}</td>;
    }
  };

  return (
    <div className="feature-tab" id="request-exports">
      <LoadingSpinner isDataLoading={isLoading} controlsName={"historyTab"} />

      <Accordion defaultExpanded label="Search & Filter">
        <Row>
          <Col className="higher-zindex-filters">
            <MissingRemittancesHistoryForm
              handleFormSubmit={setSubmittedFormData}
            />
          </Col>
        </Row>

        <Row>
          <Col className="tight-grid multi-line-filter">
            <SharedMainGrid
              data={gridData}
              dataItemKey={"paymentId"}
              aggregateColumnSettings={null}
              sendAggregatesParentCallback={sendAggregatesToParent}
              exportFileNamePrefix={"MissingRemittancesHistory"}
            >
              <GridColumn
                field="status"
                title="Status"
                filterable={true}
                sortable={true}
                groupable={false}
                cells={{ data: StatusHistoryActionCell }}
              />
              <GridColumn key={"ncpdp"} field={"ncpdp"} title={"NCPDP"} />

              <GridColumn
                key={"paymentId"}
                field={"paymentId"}
                title={"Request Number"}
              />

              <GridColumn
                key={"payer"}
                field={"payer"}
                title={"Payer"}
                className="missingRemittanceWiderGridColumns"
              />
              <GridColumn
                key={"centralPay"}
                field={"centralPay"}
                title={"Central Pay"}
                filter="boolean"
              />
              <GridColumn
                key={"checkNumber"}
                field={"checkNumber"}
                title={"Check Number"}
                cells={{ data: LinkToRemittanceDetailActionCell }}
              />

              <GridColumn
                key={"checkDate"}
                field={"checkDate"}
                title={"Check Date"}
                filter="date"
                format="{0:d}"
              />

              <GridColumn
                key={"checkAmount"}
                field={"checkAmount"}
                title={"Check Amount"}
                filter="numeric"
              />

              <GridColumn
                key={"requestor"}
                field={"requestor"}
                title={"Requestor"}
                className="missingRemittanceWiderGridColumns"
              />

              <GridColumn
                key={"requestDate"}
                field={"requestDate"}
                title={"Request Date"}
                filter="date"
                format="{0:d}"
              />

              <GridColumn
                key={"hasAttachment"}
                field={"hasAttachment"}
                title={"Attachment"}
                filter="boolean"
                sortable={true}
                groupable={false}
                style={{ textAlign: "center" }}
                cells={{ data: AttachmentDownloadCell }}
              />

              <GridColumn
                key={"received"}
                field={"received"}
                title={"Received"}
                filter="boolean"
              />
            </SharedMainGrid>
          </Col>
        </Row>
      </Accordion>

      <MissingRemittanceStatusHistoryModal
        paymentId={detailsPaymentId}
        auth={passedProps.auth}
        show={showDetailsModal}
        onClose={() => {
          setShowDetailsModal(false);
        }}
      ></MissingRemittanceStatusHistoryModal>
    </div>
  );
}
