import React from "react";
// import video from './Remit Admin Tutorial.mp4';
import video from './Remit Admin take 1.mp4';

export default function TutorialVideo(props) {

    return (
        <div>
            <video src={video} width="750" height="500"
            controls controlslist="nodownload"
            onContextMenu={e => e.preventDefault()}
            >
            </video>
        </div>
    );
}