import React from "react";

import { Row, Col } from "react-bootstrap";

import {
  DefaultColumnWidth,
  DefaultDateColumnWidth,
} from "../../grid/Kendo/KendoGridAndColumnConstants";

import { GridColumn } from "@progress/kendo-react-grid";

import SharedMainSelectableServerGrid from "../../grid/Kendo/SharedMainSelectableServerGrid";

export default function AdminRxLookupGrid({
  setShowDetailsModal,
  setSelectedRxNumber,
  setSelectedClaimId,
  setSelectedPaymentId,
  submittedFormData,
  setDetailError,
}) {
  let defaultColumnWidth = DefaultColumnWidth();

  const GridViewDetailActionCell = (props) => {
    async function editClick() {
      setShowDetailsModal(true);

      setSelectedRxNumber(props.dataItem["rxNumber"]);
      setSelectedClaimId(props.dataItem["claimId"]);
      setSelectedPaymentId(props.dataItem["paymentId"]);
    }

    return (
      <td className="noWrap" {...props.style}>
        <a
          onClick={editClick}
          style={{
            cursor: "pointer",
            textDecoration: "underline",
            color: "#015CAB",
          }}
        >
          View Details
        </a>
      </td>
    );
  };

  return (
    <Row>
      <Col className="tight-grid multi-line-filter">
        <br />

        <SharedMainSelectableServerGrid
          exportFileNamePrefix={"AdminRxLookup"}
          apiGetEndpoint={"api/AdminRxLookup/GetRxLookupResult"}
          apiSubmittedFormData={submittedFormData}
          gridErrorHandlerCallback={setDetailError}
        >
          <GridColumn
            field=""
            title="Action"
            filterable={false}
            sortable={false}
            groupable={false}
            cells={{ data: GridViewDetailActionCell }}
            width={DefaultColumnWidth()}
          />
          <GridColumn
            field="rxNumber"
            title="Rx Number"
            width={defaultColumnWidth}
            filterable={false}
          />
          <GridColumn
            field="ncpdp"
            title="NCPDP"
            width={defaultColumnWidth}
            filterable={false}
          />

          <GridColumn
            field="bin"
            title="BIN"
            width={defaultColumnWidth}
            filterable={false}
          />

          <GridColumn
            field="ndc"
            title="NDC"
            width={defaultColumnWidth}
            filterable={false}
          />

          <GridColumn
            field="serviceDate"
            title="Service Date"
            format="{0:d}"
            width={DefaultDateColumnWidth()}
            filterable={false}
          />

          <GridColumn
            field="transactionDate"
            title="Transaction Date"
            format="{0:d}"
            width={DefaultDateColumnWidth()}
            filterable={false}
          />

          <GridColumn
            field="payerName"
            title="Payer"
            width={defaultColumnWidth * 2} // lets give this one a little more space to try to prevent wrapping on entire row
            filterable={false}
          />

          <GridColumn
            field="adjAmount"
            title="Adjudicated Amount"
            format="{0:c}"
            width={defaultColumnWidth}
            filterable={false}
          />

          <GridColumn
            field="adjAmount"
            title="Paid Amount"
            format="{0:c}"
            width={defaultColumnWidth}
            filterable={false}
          />

          <GridColumn
            field="ignoreReason"
            title="Ignore Reason"
            width={defaultColumnWidth}
            filterable={false}
          />

          <GridColumn
            field="npi"
            title="Internal NPI"
            width={defaultColumnWidth}
            filterable={false}
          />

          <GridColumn
            field="dataNpi"
            title="Data NPI"
            width={defaultColumnWidth}
            filterable={false}
          />

          <GridColumn
            field="memberName"
            title="Pharmacy Name"
            width={defaultColumnWidth * 2} // lets give this one a little more space to try to prevent wrapping on entire row
            filterable={false}
          />

          <GridColumn
            field="groupName"
            title="Group"
            width={defaultColumnWidth}
            filterable={false}
          />

          <GridColumn
            field="quantityDispensed"
            title="Quantity Dispensed"
            format="{0:c}"
            width={defaultColumnWidth}
            filterable={false}
          />

          <GridColumn
            field="daySupply"
            title="Day Supply"
            format="{0:c}"
            width={defaultColumnWidth}
            filterable={false}
          />

          <GridColumn
            field="dispensingFeePaid"
            title="Dispensing Fee Paid"
            format="{0:c}"
            width={defaultColumnWidth}
            filterable={false}
          />

          <GridColumn
            field="pcn"
            title="PCN"
            width={defaultColumnWidth}
            filterable={false}
          />

          <GridColumn
            field="patientResidenceCode"
            title="Patient Residence Code"
            width={defaultColumnWidth}
            filterable={false}
          />

          <GridColumn
            field="scC1"
            title="SCC1"
            width={defaultColumnWidth}
            filterable={false}
          />

          <GridColumn
            field="scC2"
            title="SCC2"
            width={defaultColumnWidth}
            filterable={false}
          />

          <GridColumn
            field="scC3"
            title="SCC3"
            width={defaultColumnWidth}
            filterable={false}
          />

          <GridColumn
            field="submissionClarificationCodesCount"
            title="Count Of Submission Clarification Codes"
            width={defaultColumnWidth * 2} // lets give this one a little more space due to long title
            filterable={false}
            sortable={false}
          />

          <GridColumn
            field="specialPackageIndicator"
            title="Special Package Indicator"
            width={defaultColumnWidth * 1.5} // lets give this one a little more space due to long title
            filterable={false}
          />

          <GridColumn
            field="ucSubmitted"
            title="U&C Submitted"
            format="{0:c}"
            width={defaultColumnWidth}
            filterable={false}
          />

          <GridColumn
            field="awpSubmitted"
            title="AWP Submitted"
            format="{0:c}"
            width={defaultColumnWidth}
            filterable={false}
          />

          <GridColumn
            field="gadSubmitted"
            title="GAD Submitted"
            format="{0:c}"
            width={defaultColumnWidth}
            filterable={false}
          />

          <GridColumn
            field="dispenseFeeSubmitted"
            title="Dispense Fee Submitted"
            format="{0:c}"
            width={defaultColumnWidth}
            filterable={false}
          />

          <GridColumn
            field="placeOfServiceCode"
            title="Place Of Service Code"
            width={defaultColumnWidth}
            filterable={false}
          />

          <GridColumn
            field="pharmacyServiceTypeCode"
            title="Pharmacy Service Type Code"
            width={defaultColumnWidth * 1.5} // lets give this one a little more space due to long title
            filterable={false}
          />

          <GridColumn
            field="brandGenericCode"
            title="Brand/Generic Code"
            width={defaultColumnWidth}
            filterable={false}
          />
        </SharedMainSelectableServerGrid>
      </Col>
    </Row>
  );
}
