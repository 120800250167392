import axios from "axios";
import React, { useState, useEffect } from "react";
import ErrorHandler from "./../../core/ErrorHandler";
import { Row, Col } from "react-bootstrap";
import { LoadingSpinner } from "./../../layout/LoadingSpinner";
import { DSOByPayerTrackingForm } from "./DSOByPayerTrackingForm";
import Accordion from "../../Accordion";
import { constants } from "./DSOByPayerTrackingConstants";

import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process, aggregateBy } from "@progress/kendo-data-query";
import { ExcelExport } from "@progress/kendo-react-excel-export";

import { MoneyCell, AggregateMoneyFooterCell, AggregateNumberFooterCell } from "../../grid/Kendo/CustomKendoGridCells";

import GridExport from "../../export/GridExport";

import {DefaultColumnWidth, DefaultDateColumnWidth} from "../../grid/Kendo/KendoGridAndColumnConstants";

export default function DSOByPayerTracking(props) {
  const { passedProps } = props;
  const user = passedProps.user.email;
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});

  const INITIAL_GRID_DATA_STATE = { sort: [], skip: 0, take: 50 };
  const [gridData, setGridData] = useState([]);
  const [gridColumns, setGridColumns] = useState([]);
  const [gridDataState, setGridDataState] = useState(INITIAL_GRID_DATA_STATE);
  const [gridResultState, setGridResultState] = useState(gridData);
  const [gridAggregates, setGridAggregates] = useState([]);
  const [gridColumnAggregateSettings, setGridColumnAggregateSettings] =
    useState([]);
  const [customExport, setCustomExport] = useState([]);

  const [exportData, setExportData] = useState([]);
  const [exportColumns, setExportColumns] = useState([]);

  const _export = React.useRef(null);
  const _grid = React.useRef();

  function handleFormSubmit(e) {
    setIsLoading(true);
    let accessToken = passedProps.auth.getAccessToken();
    try {
      let response = axios
        .get(`api/DaysSalesOutstanding/GetAverageByPayer`, {
          params: {
            user: user,
            toDate: e.toDate,
            fromDate: e.fromDate,
            mmids: e.selectedLocation,
            pbids: e.selectedPayer,
          },
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then(onSuccess)
        .catch(onFailure);
    } catch (exception) {
      onFailure(exception);
    }
  }

  function CreateGridColumn(column) {
    let columnWidth = DefaultColumnWidth();

    let filterType = "text";
    if (column.type === "money" || column.type === "number") {
      filterType = "numeric";
    }

    if (column.type === "money" && column.showTotal) {
      return (
        <GridColumn
          key={column.field}
          filter={filterType}
          field={column.field}
          title={column.title}
          width={columnWidth}
          cells={{
            data: MoneyCell,
            footerCell: MoneyFooterCell,
          }}
          footerCell={MoneyFooterCell}
        />
      );
    } else if (column.type === "number" && column.showTotal) {
      return (
        <GridColumn
          key={column.field}
          filter={filterType}
          field={column.field}
          title={column.title}
          width={columnWidth}
          cells={{
            footerCell: NumberFooterCell,
          }}
          footerCell={NumberFooterCell}
        />
      );
    } else if (column.type === "number" && !column.showTotal) {
      return (
        <GridColumn
          key={column.field}
          filter={filterType}
          field={column.field}
          title={column.title}
          width={columnWidth}
          cells={{
            data: CustomAverageCell,
          }}
        />
      );
    } else {
      return (
        <GridColumn
          key={column.field}
          filter={filterType}
          field={column.field}
          title={column.title}
          width={columnWidth}
        />
      );
    }
  }

  const MoneyFooterCell = (props) => {
    var options = {
      aggregateData: gridAggregates,
    };

    return AggregateMoneyFooterCell(props, options);
  };

  const NumberFooterCell = (props) => {
    var options = {
      aggregateData: gridAggregates,
    };

    return AggregateNumberFooterCell(props, options);
  };

  useEffect(() => {
    let aggregates = {};
    let filterSettings = {};

    filterSettings.filter = gridDataState.filter;
    let filteredGridData = process(gridData, filterSettings);

    if (filteredGridData.data) {
      aggregates = aggregateBy(
        filteredGridData.data,
        gridColumnAggregateSettings
      );
    }

    // console.log(aggregates);
    setGridAggregates(aggregates);
  }, [gridResultState]);

  useEffect(() => {
    //We are setting export data here - needs to be applied on the entire grid data, not just what is rendered on the screen
    let filterSettings = {};
    filterSettings.filter = gridDataState.filter;
    let filteredGridData = process(gridData, filterSettings);

    setExportData(filteredGridData.data);

    if (_grid.current && _grid.current.columns) {
      //console.log(_grid.current.columns);
      setExportColumns(_grid.current.columns);
    }
  }, [gridResultState, gridData]);

  function onSuccess(response) {
    let columns = [];
    let data = response.data;

    if (data.length > 0) {
      const headerProps = constants.headerProps;
      Object.keys(data[0]).map((key) => {
        columns.push({
          field: key,
          title: headerProps[key].title,
          type: headerProps[key].type,
          showTotal: headerProps[key].showTotal,
          isCellAlert: headerProps[key].isCellAlert,
        });
      });
      setError({});
    } else {
      setError({ status: 201, Message: "No data found for given parameters" });
    }
    setGridColumns(columns);
    setGridData(data);
    setIsLoading(false);

    let moneyColumns = [];
    columns.forEach((column) => {
      if (
        (column.type === "money" || column.type === "number") &&
        column.showTotal
      ) {
        moneyColumns.push({ aggregate: "sum", field: column.field });
      }
    });
    setGridColumnAggregateSettings(moneyColumns);
  }

  function onFailure(error) {
    setError(error.response);
    setGridColumns([]);
    setGridData([]);
    setIsLoading(false);
  }

  let CustomAverageCell = (props) => {
    const isAverage = props.dataItem[props.field] > 45;

    return (
      <td
        className={isAverage ? "negativeCurrency" : ""}
        style={{ ...props.style }}
      >
        {props.dataItem[props.field]}
      </td>
    );
  };

  const onGridDataStateChange = (event) => {
    let processedData = process(gridData, event.dataState);
    setGridDataState(event.dataState);
    setGridResultState(processedData);
  };

  useEffect(() => {
    let processedData = process(gridData, gridDataState);
    setGridDataState(INITIAL_GRID_DATA_STATE);
    setGridResultState(processedData);
  }, [gridData]);

  return (
    <React.Fragment>
      <div className="feature">
        <LoadingSpinner
          isDataLoading={isLoading}
          controlsName={"dsoByPayerTracking"}
        />
        <ErrorHandler
          error={error}
          onClose={() => {
            setError({});
          }}
        />
        <Accordion defaultExpanded label="Search & Filter">
          <Row>
            <Col>
              <DSOByPayerTrackingForm handleFormSubmit={handleFormSubmit} />
            </Col>
            {/* <Col>
                            <FeatureWidget
                                title='90 Day DSO by Payer'
                                iFrameId='sisense-DSObpt-frame'
                                filters={{}}
                                dashboard={process.env.REACT_APP_Dashboard_DaySalesOutstanding}
                                widget={process.env.REACT_APP_Widget_DaySalesOutstanding}
                                settings={{ showToolbar: false, showLeftPane: false, showRightPane: false }}
                                hidden={false}
                                size="lg"
                            />
                        </Col> */}
          </Row>
        </Accordion>
        <Row className="align-items-center">
          <Col>
            <GridExport
              exportData={exportData}
              exportDataColumns={exportColumns}
              exportFileNamePrefix={"DaySalesOutstanding"}
            />
          </Col>
        </Row>

        <ExcelExport data={gridResultState} ref={_export}>
          <Row>
            <Col className="tight-grid multi-line-filter">
              <Grid
                style={{
                  height: "600px",
                }}
                ref={_grid}
                data={gridResultState}
                filterable={true}
                sortable={true}
                groupable={true}
                pageable={true}
                onDataStateChange={onGridDataStateChange}
                {...gridDataState}
              >
                {gridColumns.length > 0
                  ? gridColumns.map((singleColumn) =>
                      CreateGridColumn(singleColumn)
                    )
                  : null}
              </Grid>
            </Col>
          </Row>
        </ExcelExport>
      </div>
    </React.Fragment>
  );
}
