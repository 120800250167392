import axios from "axios";
import React, { useState, useEffect, useRef, useMemo } from "react";
import { useOktaAuth } from "@okta/okta-react";

import { Grid, GridToolbar } from "@progress/kendo-react-grid";

import { getter } from "@progress/kendo-react-common";
import ServerGridExport from "../../export/ServerGridExport";
import { GetDataWithCleanedDates } from "../../../helpers/DateHelpers";

import { IsNullOrEmpty } from "../../../helpers/NullEmptyHelpers";

import { LoadingSmallControlSpinner } from "../../layout/LoadingSmallControlSpinner";

import { useQuery } from "@tanstack/react-query";

export default function SharedMainSelectableServerGrid(props) {
  const {
    styleOverride,
    pageSize = 50,
    secondsBeforeLoadingSpinner = 0,
  } = props;

  const { oktaAuth } = useOktaAuth();

  const defaultButtonStyleClasses =
    "k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary";

  const INITIAL_MAIN_GRID_DATA_STATE = {
    skip: 0,
    take: 10,
    sort: [],
    filter: null,
  };

  const dataKey = "SharedMainServerGridMainDataKey";

  const SELECTED_FIELD = "SelectedId";
  const DATA_ITEM_KEY = props.dataItemKey;
  const idGetter = getter(DATA_ITEM_KEY);

  const gridButtons = props.gridButtons;

  const setServerGridParentData = props.setServerGridParentData;

  const gridErrorHandlerCallback = props.gridErrorHandlerCallback;

  //const [serverGridData, setServerGridData] = useState([]);

  const [serverGridDataState, setServerGridDataState] = useState(
    INITIAL_MAIN_GRID_DATA_STATE
  );

  const [error, setError] = useState({});

  const [exportColumns, setExportColumns] = useState([]);
  // const [isLoading, setIsLoading] = useState(false);
  const [exportFormat, setExportFormat] = useState();
  const [fullExportData, setFullExportData] = useState();

  const [selectedMainGridState, setSelectedMainGridState] = useState({});

  const [secondsToShowLoadingSpinner] = useState(
    (secondsBeforeLoadingSpinner || 0) * 1000
  );

  const [hasDelayedLoadingSpinner] = useState(secondsToShowLoadingSpinner > 0);

  const [timeOutPeriodPassed, setTimeOutPeriodPassed] = useState(false);

  //while we are pulling new data, we want to show the old data until the new data is ready
  const [transitionDisplayData, setTransitionDisplayData] = useState();

  const loadingTimeoutRef = useRef(null);

  //This is the largest number of records that can be exported to Excel
  const maxExportRecordsCount = 1048576;

  const [runningExportData, setRunningExportData] = useState(false);

  const exportReportOptions = {
    hasGenerateReportOption: props.hasGenerateReportOption,
    generateReportButtonTitle: props.generateReportButtonTitle,
    generateReportForSelectablesCallback:
      props.generateReportForSelectablesCallback,
  };

  const apiCallSourceData = {
    apiGetEndpoint: props.apiGetEndpoint,
    apiSubmittedFormData: props.apiSubmittedFormData,
  };

  const _grid = React.useRef();

  useEffect(() => {
    //we want fullExportData to only have data one time
    //so if it is not null, and has a length then set it to null

    if (fullExportData && fullExportData.length > 0) {
      setFullExportData(null);
    }
  }, [fullExportData]);

  useEffect(() => {
    //if the apiSubmittedFormData has changed we need to reset grid page to 1, if it is not already set to 1
    if (apiCallSourceData.apiSubmittedFormData && serverGridDataState) {
      if (serverGridDataState.skip !== 0) {
        setServerGridDataState({ ...serverGridDataState, skip: 0 });
      }
    }
  }, [apiCallSourceData.apiSubmittedFormData]);

  const apiParameters = useMemo(() => {
    if (!serverGridDataState) return {};

    if (!apiCallSourceData.apiSubmittedFormData) return {};

    let parameters = serverGridDataState;

    //now go through the form data and add every propert to the parameters
    for (const [key, value] of Object.entries(
      apiCallSourceData.apiSubmittedFormData
    )) {
      parameters[key] = value;
    }

    return parameters;
  }, [serverGridDataState, apiCallSourceData.apiSubmittedFormData]);

  function ProcessedServerGridData(response) {
    if (hasDelayedLoadingSpinner) {
      setTimeOutPeriodPassed(false);
      clearTimeout(loadingTimeoutRef.current); // Clear the timeout
    }

    let data = [];

    //if the response comes from GridDataProcessor, the data will be in a sub property called gridData, and also Total, and FieldTotals
    // FYI: Here is the model from GridDataProcessor:  { GridData = query.ToList(), Total = total, FieldTotals = calculatedTotals }
    //Otherwise the data will be the response.data and we need to distinguish between the two
    if (response.data.gridData) {
      data = response.data.gridData;
    } else if (response.data) {
      data = response.data;
    }

    if (data.length > 0) {
      setError({});
    } else {
      setError({ status: 201, Message: "No data found for given parameters" });
    }

    //console.log("databeforeCleanedDates", data);

    //process the dates to ensure they are date objects and can be formatted correctly
    //Logic will be check all records that have the word date in the header and if they are a string then convert them to a date object, then make sure it is a date object
    // let dataWithCleanedDates = data.map((t) => {
    //   let cleanedData = { ...t };
    //   for (let key in cleanedData) {
    //     if (
    //       key.toLowerCase().includes("date") &&
    //       IsDataDateType(cleanedData[key])
    //     ) {
    //       //if cleanedData[key] is null, then we do not want to parse it
    //       if (cleanedData[key] !== null)
    //         cleanedData[key] = new Date(Date.parse(cleanedData[key]));
    //     }
    //   }
    //   return cleanedData;
    // });

    let dataWithCleanedDates = GetDataWithCleanedDates(data);

    //console.log("dataWithCleanedDates", dataWithCleanedDates);

    //setExportData(dataWithCleanedDates);

    // if (_grid.current && _grid.current.columns)
    //   setExportColumns(_grid.current.columns);

    // setServerGridData(dataWithCleanedDates);
    // setIsLoading(false);

    // return;

    //set the total which is needed for paging

    if (response.data.total) {
      dataWithCleanedDates.total = response.data.total;
    }

    //set totals if provided
    if (response.data.fieldTotals) {
      dataWithCleanedDates.fieldTotals = response.data.fieldTotals;
    }

    //setServerGridParentData(dataWithCleanedDates);

    //call setServerGridParentData if it is provided
    if (setServerGridParentData) setServerGridParentData(dataWithCleanedDates);

    //setServerGridData(dataWithCleanedDates);
    //setIsLoading(false);

    return dataWithCleanedDates;
  }

  function onFailure(e) {
    setRunningExportData(false);

    //If a callback is provided, then call it
    if (gridErrorHandlerCallback) gridErrorHandlerCallback(e);

    //console.log(error);
  }

  async function GetServerGridData() {
    //if api paramss are null or empty return empty array, no need to make a call
    if (!apiParameters || Object.keys(apiParameters).length === 0) {
      return [];
    }

    //alert(JSON.stringify(apiParameters));

    let accessToken = oktaAuth.getAccessToken();

    //if we have a delay and the Timeout check is not already running, then set the timeout to show the spinner
    if (hasDelayedLoadingSpinner && !loadingTimeoutRef.current) {
      loadingTimeoutRef.current = setTimeout(() => {
        setTimeOutPeriodPassed(true);
      }, secondsToShowLoadingSpinner);
    }

    var result = await axios
      .get(apiCallSourceData.apiGetEndpoint, {
        params: apiParameters,
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        let cleanedData = ProcessedServerGridData(response);

        //set the transition data if we have a delay
        if (hasDelayedLoadingSpinner) {
          setTransitionDisplayData(cleanedData);
        }

        return cleanedData;
      })
      .catch((error) => {
        throw new Error(error);
      });

    return result;
  }

  const GetSelectedGridDataKeys = () => {
    var selectableKeys = Object.keys(selectedMainGridState);

    var selectedKeys = selectableKeys.filter(function (key) {
      return selectedMainGridState[key] === true;
    });

    return selectedKeys;
  };

  const ShouldDisableToolbarButton = (
    shouldDisableWhenNoRecordSelectedOption
  ) => {
    //if shouldDisableWhenNoRecordSelectedOption is not true, then no need to test for anything and njust return false
    if (shouldDisableWhenNoRecordSelectedOption !== true) return false;

    //now we need to run some tests to see if the button should be disabled
    //if any records are selected, then the button should be enabled

    var selectedKeys = GetSelectedGridDataKeys();

    //if there are no selected keys, then the button should be disabled
    if (selectedKeys.length === 0) return true;

    return false;
  };

  const onServerGridDataStateChange = (e) => {
    setServerGridDataState(e.dataState);
  };

  function onExportGetDataSuccess(response) {
    let data = response.data.gridData;

    setRunningExportData(true);

    setFullExportData(data);
  }

  function ExportHasCompleted() {
    setFullExportData(null);
    setRunningExportData(false);
  }

  function getExportDataParameters() {
    //Set the parameters to the serverGridDataState
    //make a copy as we do not want to change the original
    let exportDataParameters = { ...apiParameters };

    //find a property with Key of "take" and set it to the maxExportRecordsCount
    for (const [key] of Object.entries(exportDataParameters)) {
      if (key === "take") {
        exportDataParameters[key] = maxExportRecordsCount;
      }
    }

    //find a property with Key of "skip and set it to 0
    for (const [key] of Object.entries(exportDataParameters)) {
      if (key === "skip") {
        exportDataParameters[key] = 0;
      }
    }

    return exportDataParameters;
  }

  //This method will get the full data for export (Excel or CSV)
  //Logic is same as regular getServerGridData, Except:  We need to pull all the data, not just a specific page
  //We therefore need to adjust the take and skip parameters to get all the data, and since we do not want to alter the original apiParameters, we will make a copy of it and adjust the take and skip parameters
  function getFullExportServerGridData() {
    try {
      let accessToken = oktaAuth.getAccessToken();

      let parameters = getExportDataParameters();

      //make an api request to get the full data for export

      axios
        .get(apiCallSourceData.apiGetEndpoint, {
          params: parameters,
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then(onExportGetDataSuccess)
        .catch(onFailure);
    } catch (exception) {
      onFailure(exception);
    }
  }

  function GetAllExportDataCallback(exportFormat) {
    setRunningExportData(true);

    setExportColumns(_grid.current.columns);
    setExportFormat(exportFormat);
    getFullExportServerGridData();
  }

  let { data: serverGridData, isLoading } = useQuery({
    queryKey: [dataKey + apiCallSourceData.apiGetEndpoint, apiParameters],
    queryFn: async () => await GetServerGridData(),
  });

  //This is the data that will be bound to the grid
  //it will be either the serverGridData or the transitionDisplayData (while we are waiting for the new data to load)
  const gridBindingData = useMemo(() => {
    let total = 0;

    let bindingData = serverGridData || transitionDisplayData;

    // if bindingdata has a total, then use that

    if (bindingData && bindingData.total) {
      total = bindingData.total;
    }

    return { bindingData, total };
  }, [serverGridData, transitionDisplayData]);

  function shouldShowSpinner() {
    if (runningExportData) {
      return true;
    }

    if (!isLoading) return false;

    //if there is no delay then we always show the spinner while waiting for the data
    if (!hasDelayedLoadingSpinner) {
      return isLoading;
    }

    // if we get here then we do have a delay
    // if we are loading and the total is 0, this means we are waiting for initial data to load and do not have the transition data to show
    // so we show the spinner
    if (isLoading && gridBindingData.total < 1) {
      return true;
    }

    //if we are loading the timeout passed then we show a spinner
    if (isLoading && timeOutPeriodPassed) {
      return true;
    }

    // if (fetchingExportData && timeOutPeriodPassed) {
    //   return true;
    // }

    if (runningExportData) {
      return true;
    }

    //should never get here but just in case we do somehow then return false to not show the spinner
    return false;
  }

  return (
    <React.Fragment>
      <div className={shouldShowSpinner() ? "hidden-div" : "visible-element"}>
        <ServerGridExport
          exportFileNamePrefix={props.exportFileNamePrefix}
          exportEnabled={
            IsNullOrEmpty(gridBindingData.bindingData) ? false : true
          }
          exportData={fullExportData}
          exportFormat={exportFormat}
          exportDataColumns={exportColumns}
          exportInitiated={runningExportData}
          getAllExportDataCallback={GetAllExportDataCallback}
          exportCompletedCallback={ExportHasCompleted}
        />
      </div>

      {shouldShowSpinner() ? (
        <LoadingSmallControlSpinner />
      ) : (
        <React.Fragment>
          <Grid
            ref={_grid}
            data={gridBindingData.bindingData}
            pageable
            sortable
            filterable
            {...serverGridDataState}
            onDataStateChange={onServerGridDataStateChange}
            total={gridBindingData.total}
          >
            <GridToolbar>
              {gridButtons &&
                gridButtons.map((thisButton) => (
                  <button
                    key={thisButton.text
                      .replace(/\s+/g, "")
                      .trim()
                      .toLowerCase()}
                    title={thisButton.text}
                    className={
                      thisButton.styleOverride || defaultButtonStyleClasses
                    }
                    disabled={ShouldDisableToolbarButton(
                      thisButton.disableWhenNoRecordSelected
                    )}
                    onClick={() => {
                      thisButton.callback(GetSelectedGridDataKeys());
                    }}
                  >
                    {thisButton.text}
                  </button>
                ))}
            </GridToolbar>
            {props.children}
          </Grid>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

//This method takes in a currentArray, buttonText, and buttonCallback and returns a new array with the button added to the end of the currentArray
export function AddGridButton(
  currentArray,
  buttonText,
  buttonCallback,
  disableWhenNoRecordSelected = true,
  styleOverride = null
) {
  let newArrayItem = {
    text: buttonText,
    callback: buttonCallback,
    disableWhenNoRecordSelected: disableWhenNoRecordSelected,
    styleOverride: styleOverride,
  };

  let newArray = [...currentArray, newArrayItem];

  return newArray;
}
