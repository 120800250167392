import React, { useState, useEffect } from "react";
import { DetailsModal } from "../../grid/DetailsModal";
import { Row, Col } from "react-bootstrap";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { IsNullOrWhitespace } from "../../../helpers/StringHelpers";
import { LoadingSpinner } from "../../layout/LoadingSpinner";
import ErrorHandler from "../../core/ErrorHandler";
import axios from "axios";
import "./MissingRemittanceModal.css";

import { DefaultColumnWidth } from "../../grid/Kendo/KendoGridAndColumnConstants";

export default function MissingRemittanceStatusHistoryModal(props) {
  const { paymentId, show, onClose, auth } = props;

  const [isLoading, setIsLoading] = useState(false);

  const [gridData, setGridData] = useState([]);
  const [error, setError] = useState({});

  const _grid = React.useRef();

  useEffect(() => {
    setError({});
  }, [onClose]);

  useEffect(() => {
    if (!paymentId) return;

    //added show as a dependency to trigger the grid data fetch when the modal is shown
    //need this because even if the paymentId is the same as the previous one, we still need to requery in case an automated process updated the data
    if (!show) return;

    getMainGridData();
  }, [paymentId, show]);

  function getMainGridData() {
    try {
      setIsLoading(true);

      let accessToken = auth.getAccessToken();

      let parameters = {
        paymentId: paymentId,
      };

      axios
        .get("api/MissingRemittances/GetPaymentStatusHistory", {
          params: parameters,
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then(onSuccess)
        .catch(onFailure);
    } catch (exception) {
      onFailure(exception);
    }
  }

  function onSuccess(response) {
    let data = response.data;

    //sort by request date desc
    data.sort((a, b) => {
      return new Date(b.requestDate) - new Date(a.requestDate);
    });

    //console.log(data);
    //alert("got data " + data.length);

    if (data.length > 0) {
      //const headerProps = constants.headerProps;

      setError({});
    } else {
      setError({ status: 201, Message: "No data found for given parameters" });
    }

    let dataWithCleanedDates = response.data.map((t) => {
      return {
        ...t,
        requestDate: IsNullOrWhitespace(t.requestDate)
          ? ""
          : new Date(Date.parse(t.requestDate)),

        checkDate: IsNullOrWhitespace(t.checkDate)
          ? ""
          : new Date(Date.parse(t.checkDate)),

        //Add a sequentialindex to the data - this will be a unique key for the grid
        sequentialIndex: response.data.indexOf(t) + 1,
      };
    });

    setGridData(dataWithCleanedDates);

    setIsLoading(false);
  }

  function onFailure(error) {
    setIsLoading(false);

    //console.log(error);
  }

  return (
    <DetailsModal
      overrideClass="grid-details-modal2"
      title="Missing Remittance Status History"
      show={show}
      handleClose={onClose}
    >
      <LoadingSpinner
        isDataLoading={isLoading}
        controlsName={"MissingRemittanceStatusHistoryModal"}
      />
      <ErrorHandler
        error={error}
        onClose={() => {
          setError({});
        }}
      />

      <div className="fee-and-adjustment-detail-summary-wrapper">
        <br />

        <Row>
          <Col className="tight-grid multi-line-filter">
            {/* will use the grid driectly as would like to set some specific attributes that packeged grid do not provide options to override */}

            <Grid
              style={{ height: "30em" }}
              scrollable="virtual"
              ref={_grid}
              data={gridData}
              filterable={false}
              sortable={false}
              groupable={false}
              pageable={false}
              dataItemKey={"sequentialIndex"}
              selectable={{
                enabled: true,
                drag: false,
                cell: false,
                mode: "multiple",
              }}
              onDataStateChange={() => { }}
              onSelectionChange={() => { }}
              onHeaderSelectionChange={() => { }}
            >
              <GridColumn
                field="requestDate"
                title="Status Date"
                format="{0:d}"
                width={DefaultColumnWidth()}
              />
              <GridColumn
                field="description"
                title="Status"
                width={DefaultColumnWidth()}
              />
              <GridColumn field="note" title="Note" width={DefaultColumnWidth()} />
              <GridColumn
                field="statusUpdateUser"
                title="User"
                width={DefaultColumnWidth()}
              />
            </Grid>
          </Col>
        </Row>
      </div>
    </DetailsModal>
  );
}
