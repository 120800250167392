import axios from "axios";
import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { LoadingSpinner } from "../../layout/LoadingSpinner";
import Accordion from "../../Accordion";
import ErrorHandler from "../../core/ErrorHandler";
import AdminMissingRemittanceForm from "./AdminMissingRemittanceForm";
import SharedMainGrid from "../../grid/Kendo/SharedMainGrid";
import { MoneyCell } from "../../grid/Kendo/CustomKendoGridCells";
import {
  DefaultColumnWidth,
  DefaultDateColumnWidth,
  DefaultBooleanOrShortWidthColumnWidth,
} from "../../grid/Kendo/KendoGridAndColumnConstants";
import { GridColumn as Column } from "@progress/kendo-react-grid";
import { GetDataWithCleanedDates } from "../../../helpers/DateHelpers.js";

export default function AdminMissingRemittance(props) {
  const { passedProps } = props;
  const user = passedProps.user.email;
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});
  const [submittedFormData, setSubmittedFormData] = useState(null);
  const [mainGridData, setMainGridData] = useState([]);
  const pmidKey = "remittanceId";

  function handleFormSubmit(e) {
    setIsLoading(true);
    setSubmittedFormData(e);
  }

  function handleFormReset(e) {
    setMainGridData([]);
  }

  useEffect(() => {
    // if (submittedFormData) {
    //   console.log("submittedFormData: " + submittedFormData);
    // }

    getMainGridData();
  }, [submittedFormData]);

  function getMainGridData() {
    if (submittedFormData) {
      try {
        let accessToken = passedProps.auth.getAccessToken();

        let parameters = {
          user: user,
          fromDate: submittedFormData.fromDate
            ? submittedFormData.fromDate.toLocaleDateString()
            : "",
          toDate: submittedFormData.toDate
            ? submittedFormData.toDate.toLocaleDateString()
            : "",
          checkNumber: submittedFormData.checkNumber,
          payerId:
            submittedFormData.selectedPayer === "" ||
            submittedFormData.selectedPayer === null
              ? null
              : submittedFormData.selectedPayer.value,
          mpid: submittedFormData.mpid,
          nCPDP:
            submittedFormData.selectedLocation === "" ||
            submittedFormData.selectedLocation === null
              ? null
              : submittedFormData.selectedLocation.value,
          parentCompanyId:
            submittedFormData.selectedParentCompany === "" ||
            submittedFormData.selectedParentCompany === null
              ? null
              : submittedFormData.selectedParentCompany.value,
        };

        axios
          .get("api/MissingRemittances/GetAdminMissingRemittance", {
            params: parameters,
            headers: { Authorization: `Bearer ${accessToken}` },
          })
          .then(presentGridData)
          .catch(onFailure);
      } catch (exception) {
        onFailure(exception);
      }
    }
  }
  function presentGridData(response) {
    setIsLoading(false);

    if (response.data.length > 0) {
      // let dataWithCleanedDates = response.data.map((t) => {
      //     return {
      //         ...t,
      //         checkDate: IsNullOrWhitespace(t.checkDate)
      //             ? ""
      //             : new Date(Date.parse(t.checkDate)),
      //         reqDate: IsNullOrWhitespace(t.reqDate)
      //             ? ""
      //             : new Date(Date.parse(t.reqDate)),
      //     };
      // });

      let dataWithCleanedDates = GetDataWithCleanedDates(response.data);

      setMainGridData(dataWithCleanedDates);
    } else {
      setMainGridData([]);
      setError({ status: 201, Message: "No data found for given parameters" });
    }
  }
  function onFailure(error) {
    setIsLoading(false);

    if (error.response) {
      switch (error.response.config.url) {
        case "api/MissingRemittances/GetAdminMissingRemittance":
          setError(error);
          break;
        default:
          break;
      }
    } else {
      //console.log(error);
    }
  }
  const StatusActionCell = (props) => {
    async function editClick() {
      alert("clicked");
      //props.dataItem["paymentId"]
    }

    return (
      <td className="noWrap" {...props.style}>
        <a
          onClick={editClick}
          style={{
            cursor: "pointer",
            textDecoration: "underline",
            color: "#015CAB",
          }}
        >
          Edit/Update
        </a>
      </td>
    );
  };

  return (
    <React.Fragment>
      <div className="feature" id="admin-missing-remittance">
        <LoadingSpinner
          isDataLoading={isLoading}
          controlsName={"AdminMissingRemittance"}
        />
        <ErrorHandler
          error={error}
          onClose={() => {
            setError({});
          }}
        />

        <Accordion defaultExpanded label="Search & Filter">
          <Row>
            <Col className="higher-zindex-filters">
              <AdminMissingRemittanceForm
                handleFormSubmit={handleFormSubmit}
                passedProps={passedProps}
                handleFormReset={handleFormReset}
              />
            </Col>
          </Row>
        </Accordion>
        <Row>
          <Col className="tight-grid multi-line-filter">
            <SharedMainGrid
              data={mainGridData}
              aggregateColumnSettings={null}
              sendAggregatesParentCallback={() => {}}
              dataItemKey={pmidKey}
              styleOverride={{ height: "600px" }}
              exportFileNamePrefix={"AdminMissinghRemittances"}
            >
              <Column
                field=""
                title="Action"
                filterable={false}
                sortable={false}
                groupable={false}
                cells={{ data: StatusActionCell }}
                width={DefaultColumnWidth()}
              />
              <Column
                field="payer"
                title="Payer"
                width={DefaultColumnWidth()}
              />
              <Column field="mpid" title="MPID" width={DefaultColumnWidth()} />
              <Column
                field="checkNumber"
                title="Check Number"
                width={DefaultColumnWidth()}
              />
              <Column
                field="checkAmount"
                title="Amount"
                width={DefaultColumnWidth()}
                filter="numeric"
                cells={{ data: MoneyCell }}
              />
              <Column
                field="checkDate"
                title="Check Date"
                width={DefaultDateColumnWidth()}
                filter="date"
                format="{0:d}"
              />
              <Column
                field="reqDate"
                title="Req Date"
                width={DefaultDateColumnWidth()}
                filter="date"
                format="{0:d}"
              />
              <Column
                field="fileSource"
                title="File Source"
                width={DefaultColumnWidth()}
              />
              <Column
                field="ncpdp"
                title="NCPDP"
                width={DefaultColumnWidth()}
              />
              <Column
                field="requestBy"
                title="Requested By"
                width={DefaultColumnWidth()}
              />

              <Column
                field="centralPay"
                title="Central Pay"
                filter="boolean"
                width={DefaultBooleanOrShortWidthColumnWidth()}
              />
            </SharedMainGrid>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}
