import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Form, Col, Row, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import MemberLocationDropdown from "../../form/MemberLocationDropdown";

import FormValidation from "../../form/validation/FormValidation";
import { UserContext } from "../../context/UserContext";

export function DateRangeForm(props) {
  //NOTE TODO: This is the original logic, but I'm reverting this for now for testing
  const [fromDate, setFromDate] = useState(getFromDate());
  const [toDate, setToDate] = useState(new Date());

  // const [fromDate, setFromDate] = useState(new Date(2022, 5, 15))
  // const [toDate, setToDate] = useState(new Date(2022, 6, 15))
  const [reportType, setReportType] = useState("unreconciled");
  const [dateType, setDateType] = useState("dispense");
  const [includeClaimsWithNoPayer, setIncludeClaimsWithNoPayer] =
    useState(true);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const userContext = useContext(UserContext);

  useEffect(() => {
    setLoaded(false);
  }, [
    userContext.locations,
    userContext.metricLocations,
    userContext.recLocations,
    userContext.isUnrestrictedEraPath,
  ]);
  
  function getFromDate() {
    let date = new Date();
    date.setDate(date.getDate() - 15);
    return new Date(date);
  }

  useEffect(() => {
    if (!loaded && selectedLocations.length > 0) {
      setLoaded(true);
      submitForm();
    }
  }, [selectedLocations]);

  function submitForm() {
    let parameters = {
      toDate: toDate,
      fromDate: fromDate,
      selectedLocations: selectedLocations,
      requiredFields: ["toDate", "fromDate"],
    };

    let validationIssueMessage = FormValidation(parameters);

    if (validationIssueMessage) {
      alert(validationIssueMessage);
    } else {
      props.handleFormSubmit({
        toDate: toDate.toLocaleDateString(),
        fromDate: fromDate.toLocaleDateString(),
        reportType: reportType,
        dateType: dateType,
        includeClaimsWithNoPayer: includeClaimsWithNoPayer,
        mmid: selectedLocations.join(","),
      });
    }
  }
  function handleSubmit(event) {
    event.preventDefault();
    submitForm();
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Row className="justify-content-start">
        <Col md="auto" className="d-flex flex-column">
          <Form.Label>Start Date</Form.Label>
          <Form.Control
            name="fromDate"
            as={DatePicker}
            selected={fromDate}
            onChange={(date) => setFromDate(date)}
          />
        </Col>
        <Col md="auto" className="d-flex flex-column">
          <Form.Label>End Date</Form.Label>
          <Form.Control
            name="toDate"
            as={DatePicker}
            selected={toDate}
            onChange={(date) => setToDate(date)}
          />
        </Col>
        <Col md="auto" className="d-flex flex-column">
          <Form.Label>Location (NCPDP)</Form.Label>
          <Form.Control
            as={MemberLocationDropdown}
            onChange={(locations) => setSelectedLocations(locations)}
            programSubscription={"Rec2.0"}
          />
        </Col>

        <Col md="auto" className="d-flex flex-column">
          <Form.Label>Report Type</Form.Label>
          <div key="ReportType" className="mb-3 mr-2">
            <Form.Check
              custom="true"
              type="radio"
              id="ReportTypeDR-1"
              name="ReportType"
              label="Total Unreconciled Claims"
              onChange={(event) => setReportType(event.target.value)}
              value="unreconciled"
              defaultChecked
            />
            <Form.Check
              custom="true"
              type="radio"
              id="ReportTypeDR-2"
              name="ReportType"
              label="Total Unpaid Claims"
              onChange={(event) => setReportType(event.target.value)}
              value="unpaid"
            />
            <Form.Check
              custom="true"
              type="radio"
              id="ReportTypeDR-3"
              name="ReportType"
              label="Claims Pending Receipt of Check"
              onChange={(event) => setReportType(event.target.value)}
              value="pending"
            />
          </div>
        </Col>
        <Col md="auto" className="d-flex flex-column">
          <Form.Label>Date Type</Form.Label>
          <div key="DateType" className="mb-3 mr-2">
            <Form.Check
              custom="true"
              type="radio"
              id="DateTypeDR-1"
              name="DateType"
              label="Dispense date"
              onChange={(event) => setDateType(event.target.value)}
              value="dispense"
              defaultChecked
            />
            <Form.Check
              custom="true"
              type="radio"
              id="DateTypeDR-2"
              name="DateType"
              label="Transaction date"
              onChange={(event) => setDateType(event.target.value)}
              value="transaction"
            />
          </div>
        </Col>
        <Col md="auto" className="d-flex flex-column">
          <div key="DateRangeControllers" className="mb-3 mt-3 pt-3">
            <Form.Check
              custom="true"
              type="checkbox"
              id="DateRangeControllers-2"
              name="includeClaimsWithNoPayer"
              label="Include Claims with Payers not Setup"
              onChange={(event) =>
                setIncludeClaimsWithNoPayer(event.target.checked)
              }
              defaultChecked
            />
          </div>
        </Col>

        <Col md="auto" className="d-flex flex-column">
          <Button className="inline-show-result-button" size="sm" type="submit">
            Show Results
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

DateRangeForm.propTypes = {
  handleFormSubmit: PropTypes.func.isRequired,
};
