import React, { useState, useEffect } from "react";
import axios from "axios";
import { Row, Col } from "react-bootstrap";
import CopayAdjustmentManagerForm from "./CopayAdjustmentManagerForm";
import Accordion from "../../Accordion";
// import { CopayAdjustmentWidget } from "./CopayAdjustmentWidget";
import { LoadingSpinner } from "./../../layout/LoadingSpinner";
import ErrorHandler from "./../../core/ErrorHandler";
import { constants } from "./CopayAdjustmentManagerConstants";

import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process, aggregateBy } from "@progress/kendo-data-query";

import {
  MoneyCell,
  AggregateMoneyFooterCell,
} from "../../grid/Kendo/CustomKendoGridCells";
import { GetDataWithCleanedDates } from "../../../helpers/DateHelpers.js";
// import { ApplicationInsights } from "../../../../src/components/AppInsights/TelemetryService";

import GridExport from "../../export/GridExport";

import {
  DefaultColumnWidth,
  DefaultDateColumnWidth,
} from "../../grid/Kendo/KendoGridAndColumnConstants";
import {
  Base64ToArrayBuffer,
  SaveByteArray,
} from "../../../helpers/Base64ArrayTranslator";

export default function CopayAdjustmentManager(props) {
  const { passedProps } = props;
  const user = passedProps.user.email;
  const [submittedFormData, setSubmittedFormData] = useState(null);
  const [error, setError] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const INITIAL_GRID_DATA_STATE = { sort: [], skip: 0, take: 50 };
  const [gridData, setGridData] = useState([]);
  const [gridColumns, setGridColumns] = useState([]);
  const [gridDataState, setGridDataState] = useState(INITIAL_GRID_DATA_STATE);
  const [gridResultState, setGridResultState] = useState(gridData);
  const [gridAggregates, setGridAggregates] = useState([]);
  const [gridColumnAggregateSettings, setGridColumnAggregateSettings] =
    useState([]);
  const [customExport, setCustomExport] = useState([]);

  const [exportData, setExportData] = useState([]);
  const [exportColumns, setExportColumns] = useState([]);

  const _export = React.useRef(null);
  const _grid = React.useRef();

  useEffect(() => {
    if (submittedFormData) {
      let accessToken = passedProps.auth.getAccessToken();
      try {
        let response = axios
          .get(`api/CopayAdjustmentManager/GetCopayAdjustmentData`, {
            params: {
              user: user,
              FromDate: submittedFormData.fromDate,
              ToDate: submittedFormData.toDate,
              Locations: submittedFormData.locations,
              Payers: submittedFormData.payers,
              DateType: submittedFormData.dateType,
            },
            headers: { Authorization: `Bearer ${accessToken}` },
          })
          .then(onSuccess)
          .catch(onFailure);
      } catch (exception) {
        onFailure(exception);
      }
    }
  }, [submittedFormData]);

  useEffect(() => {
    //We are setting export data here - needs to be applied on the entire grid data, not just what is rendered on the screen
    let filterSettings = {};
    filterSettings.filter = gridDataState.filter;
    let filteredGridData = process(gridData, filterSettings);

    setExportData(filteredGridData.data);

    if (_grid.current && _grid.current.columns) {
      setExportColumns(_grid.current.columns);
    }
  }, [gridResultState, gridData]);

  function CreateGridColumn(column) {
    // let columnWidth = DefaultColumnWidth();
    let columnWidth = 150;
    if (column.field === "pharmacyName") {
      columnWidth = 210;
    }

    let filterType = "text";
    if (column.type === "money" || column.type === "number") {
      filterType = "numeric";
    }

    if (column.type === "money" && column.showTotal) {
      return (
        <GridColumn
          key={column.field}
          filter={filterType}
          field={column.field}
          title={column.title}
          width={columnWidth}
          locked={column.isLocked}
          cells={{
            data: MoneyCell,
            footerCell: MainGridAggregateMoneyFooterCell,
          }}
          footerCell={MainGridAggregateMoneyFooterCell}
        />
      );
    } else if (column.type === "money" && !column.showTotal) {
      return (
        <GridColumn
          key={column.field}
          filter={filterType}
          field={column.field}
          title={column.title}
          width={columnWidth}
          locked={column.isLocked}
          cells={{
            data: MoneyCell,
          }}
        />
      );
    } else if (column.type === "date") {
      return (
        <GridColumn
          key={column.field}
          filter={column.type}
          field={column.field}
          title={column.title}
          width={columnWidth}
          format="{0:d}"
          locked={column.isLocked}
        />
      );
    } else if (column.type === "number" && column.showTotal) {
      return (
        <GridColumn
          key={column.field}
          filter={filterType}
          field={column.field}
          title={column.title}
          width={columnWidth}
          locked={column.isLocked}
          cells={{
            footerCell: AggregateFooterCell,
          }}
          footerCell={AggregateFooterCell}
        />
      );
    } else {
      return (
        <GridColumn
          key={column.field}
          filter={filterType}
          field={column.field}
          title={column.title}
          width={columnWidth}
          locked={column.isLocked}
        />
      );
    }
  }

  function ExportXL() {
    try {
      let accessToken = passedProps.auth.getAccessToken();

      let parameters = {};

      axios
        .get("api/CopayAdjustmentManager/DownloadCopayReport", {
          params: parameters,
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then((response) => {
          const base64Response = response.data.fileStream;
          const fileName = response.data.fileName;
          let downloadBytes = Base64ToArrayBuffer(base64Response);

          SaveByteArray(fileName, downloadBytes);
        })
        .catch(() => onDownloadAttachmentFailure());
    } catch (exception) {
      onDownloadAttachmentFailure(() => onDownloadAttachmentFailure());
    }
  }
  function onDownloadAttachmentFailure() {
    let msg = `Unable to download Copay report.`;

    alert(msg);
  }

  const handleExportExcel = () => {
    ExportXL();
  };

  const MainGridAggregateMoneyFooterCell = (props) => {
    var options = {
      aggregateData: gridAggregates,
    };

    return AggregateMoneyFooterCell(props, options);
  };

  const AggregateFooterCell = (props) => {
    let displayAggregateValue = 0;
    if (gridAggregates[props.field]) {
      displayAggregateValue = gridAggregates[props.field].sum;
    }

    return <td {...props.tdProps}>{displayAggregateValue}</td>;
  };

  useEffect(() => {
    let aggregates = {};
    let filterSettings = {};

    filterSettings.filter = gridDataState.filter;
    let filteredGridData = process(gridData, filterSettings);

    if (filteredGridData.data) {
      aggregates = aggregateBy(
        filteredGridData.data,
        gridColumnAggregateSettings
      );
    }

    // console.log(aggregates);
    setGridAggregates(aggregates);
  }, [gridResultState]);

  function onSuccess(response) {
    let columns = [];
    let data = response.data;
    if (data.length > 0) {
      const headerProps = constants.headerProps;
      Object.keys(data[0]).map((key) => {
        return columns.push({
          field: key,
          title: headerProps[key].title,
          type: headerProps[key].type,
          fixedLeft: headerProps[key].fixedLeft,
          hidden: headerProps[key].hidden,
          showTotal: headerProps[key].showTotal,
          truncatable: headerProps[key] ? headerProps[key].truncatable : false,
          isLocked: headerProps[key].isLocked,
        });
      });
      setError({});
    } else {
      setError({ status: 201, Message: "No data found for given parameters" });
    }

    // let dataWithCleanedDates = response.data.map((t) => {
    //   return {
    //     ...t,
    //     serviceDate: IsNullOrWhitespace(t.serviceDate)
    //       ? ""
    //       : new Date(Date.parse(t.serviceDate)),
    //     transactionDate: IsNullOrWhitespace(t.transactionDate)
    //       ? ""
    //       : new Date(Date.parse(t.transactionDate)),
    //     checkDate: IsNullOrWhitespace(t.checkDate)
    //       ? ""
    //       : new Date(Date.parse(t.checkDate)),
    //   };
    // });

    let dataWithCleanedDates = GetDataWithCleanedDates(response.data);

    setGridColumns(columns);
    setGridData(dataWithCleanedDates);
    setIsLoading(false);

    let moneyColumns = [];
    columns.forEach((column) => {
      if (
        (column.type === "money" || column.type === "number") &&
        column.showTotal
      ) {
        moneyColumns.push({ aggregate: "sum", field: column.field });
      }
    });
    setGridColumnAggregateSettings(moneyColumns);
  }

  function onFailure(error) {
    setIsLoading(false);
    setError(error.response);
    setGridData([]);
  }
  function handleFormSubmit(e) {
    setGridData([]);
    setIsLoading(true);
    setSubmittedFormData(e);
  }

  const excelExport = () => {
    if (_export.current !== null) {
      let filterAndSortSettings = {};
      filterAndSortSettings.filter = gridDataState.filter;
      filterAndSortSettings.sort = gridDataState.sort;
      var shallowCopy = JSON.parse(JSON.stringify(gridData));
      let processedData = process(shallowCopy, filterAndSortSettings);
      let moneyColumns = gridColumns.filter((obj) => obj.type === "money");
      processedData.data.forEach((col) => {
        var data = Object.keys(col).map((keys) => {
          var isMoneyCol = moneyColumns.find((obj) => obj.field === keys);
          if (isMoneyCol != undefined) {
            col[keys] = currencyFormat(col[keys]);
          }
          return col;
        });
        col = data;
      });
      _export.current.save(processedData, _grid.current.columns);
      setGridData(gridData);
    }
  };

  function currencyFormat(num) {
    try {
      if (num.indexOf("$") > -1) return num;
      else
        return "$" + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    } catch (err) {
      return "$" + num;
    }
  }

  const onGridDataStateChange = (event) => {
    let processedData = process(gridData, event.dataState);
    setGridDataState(event.dataState);
    setGridResultState(processedData);
  };

  useEffect(() => {
    let processedData = process(gridData, gridDataState);
    setGridDataState(INITIAL_GRID_DATA_STATE);
    setGridResultState(processedData);
  }, [gridData]);

  return (
    <div className="feature">
      <LoadingSpinner
        isDataLoading={isLoading}
        controlsName={"RemitRcptVerification"}
      />
      <ErrorHandler
        error={error}
        onClose={() => {
          setError({});
        }}
      />

      <Accordion defaultExpanded label="Search & Filter">
        <Row>
          <Col className="higher-zindex-filters">
            <CopayAdjustmentManagerForm
              handleFormSubmit={handleFormSubmit}
              handleExportExcel={handleExportExcel}
            />
          </Col>
        </Row>
      </Accordion>
      <Row className="align-items-center">
        <Col>
          <GridExport
            exportData={exportData}
            exportDataColumns={exportColumns}
            exportFileNamePrefix={"CoPayAdjustmentManager"}
          />
        </Col>
      </Row>

      <Row>
        <Col className="tight-grid multi-line-filter">
          <Grid
            style={{
              maxHeight: "37em",
            }}
            ref={_grid}
            data={gridResultState}
            filterable={true}
            sortable={true}
            groupable={true}
            pageable={true}
            onDataStateChange={onGridDataStateChange}
            {...gridDataState}
          >
            {gridColumns.length > 0
              ? gridColumns.map((singleColumn) =>
                  CreateGridColumn(singleColumn)
                )
              : null}
          </Grid>
        </Col>
      </Row>
    </div>
  );
}
