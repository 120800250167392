import axios from "axios";
import * as XLSX from "xlsx";
import { format } from "date-fns";

export default async function SingleCheckExcelExportWrapper(
  pmid,
  accessToken,
  setIsLoadingFunction
) {
  setIsLoadingFunction(true);
  try {
    const response = await axios.get(`api/Check/GetSingleCheckExcelExport`, {
      params: { pmid: pmid },
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    var dataSource = response.data;
    var workbook = XLSX.utils.book_new();

    var payerLocation =
      dataSource.payerCity +
      ", " +
      dataSource.payerState +
      " " +
      dataSource.payerZip;
    var parentCompanyLocation =
      dataSource.parentCompanyCity +
      ", " +
      dataSource.parentCompanyState +
      " " +
      dataSource.parentCompanyZip;

    var paymentSection = [];

    for (var count = 0; count < dataSource.paymentDataSection.length; count++) {
      var paymentSectionItem = dataSource.paymentDataSection[count];
      var feeCode = dataSource.paymentFeeCodeSection.find(
        (x) => x.pyid == paymentSectionItem.pyid
      );
      paymentSection.push([
        paymentSectionItem.rxNumber,
        DateFormat(paymentSectionItem.serviceDate),
        paymentSectionItem.type,
        currencyFormat(paymentSectionItem.billed),
        currencyFormat(paymentSectionItem.copay),
        feeCode == null ? "" : feeCode.group,
        feeCode == null ? "" : feeCode.reason,
        currencyFormat(paymentSectionItem.adjusted),
        currencyFormat(paymentSectionItem.paid),
        paymentSectionItem.claimNumber,
        paymentSectionItem.patientFirstName,
      ]);
    }

    var checkAdjestmentSection = [];
    for (
      var count = 0;
      count < dataSource.checkAdjustmentSection.length;
      count++
    ) {
      var checkAdjestmentItem = dataSource.checkAdjustmentSection[count];
      checkAdjestmentSection.push([
        checkAdjestmentItem.facility,
        checkAdjestmentItem.reason,
        currencyFormat(checkAdjestmentItem.amount),
      ]);
    }

    var checkSummarySection = [];
    for (
      var count = 0;
      count < dataSource.checkSummarySection.length;
      count++
    ) {
      var checkSummarySectionItem = dataSource.checkSummarySection[count];
      checkSummarySection.push([
        checkSummarySectionItem.memberName,
        currencyFormat(checkSummarySectionItem.paid),
        currencyFormat(checkSummarySectionItem.adjusted),
        currencyFormat(checkSummarySectionItem.total),
      ]);
    }

    var totalPaid = dataSource.checkSummarySection.reduce(
      (acc, item) => acc + item.paid,
      0
    );
    var totalAdjusted = dataSource.checkSummarySection.reduce(
      (acc, item) => acc + item.adjusted,
      0
    );

    var excelArraySource = [
      ["Payer", "Payee", "Payment"],
      [
        dataSource.payerName,
        dataSource.parentCompanyName,
        "Reference:" + dataSource.checkNumber,
      ],
      [
        dataSource.payerAddressLine1,
        dataSource.parentCompanyAddressLine1,
        "Date:" + DateFormat(dataSource.checkDate),
      ],
      [
        payerLocation,
        parentCompanyLocation,
        "Amount:" + currencyFormat(dataSource.checkAmount),
      ],
      ,
      [
        // dataSource.paymentDataSection[0].ncpdp +
        //   " " +
        //   dataSource.parentCompanyName,

        //check if paymentDataSection has any values and if has any iteems with a property of ncpd, otherwise return empty string
        (dataSource.paymentDataSection.some((item) => item.ncpdp)
          ? "NCPDP: " + dataSource.paymentDataSection[0].ncpdp + " "
          : "") + dataSource.parentCompanyName,
      ],
      [
        "Rx Num",
        "Disp Dt",
        "Ty",
        "Billed",
        "Copay",
        "GC",
        "RC",
        "Adjust",
        "Paid",
        "Claim Number",
        "Patient Name",
      ],
    ];

    for (var count = 0; count < paymentSection.length; count++) {
      excelArraySource.push(paymentSection[count]);
    }

    excelArraySource.push([]); //Empty line
    excelArraySource.push(["Check Adjustments"]);
    excelArraySource.push(["Facility", "Reason", "Amount"]);

    for (var count = 0; count < checkAdjestmentSection.length; count++) {
      excelArraySource.push(checkAdjestmentSection[count]);
    }

    excelArraySource.push([]); //Empty line
    excelArraySource.push(["Check Summary"]);
    excelArraySource.push(["Facility", "Paid", "Adjusted", "Total"]);

    for (var count = 0; count < checkSummarySection.length; count++) {
      excelArraySource.push(checkSummarySection[count]);
    }

    excelArraySource.push([]); //Empty line
    excelArraySource.push(["Total Paid:", currencyFormat(totalPaid)]);
    excelArraySource.push(["Total Adjusted:", currencyFormat(totalAdjusted)]);
    excelArraySource.push([
      "Total:",
      currencyFormat(totalPaid + totalAdjusted),
    ]);

    var worksheet = XLSX.utils.aoa_to_sheet(excelArraySource);
    XLSX.utils.book_append_sheet(workbook, worksheet, "TestSheet");

    XLSX.writeFileXLSX(workbook, "REMITANCELOOKUP.xlsx", { type: "string" });
  } catch (e) {
    console.log(e);
  }
  setIsLoadingFunction(false);
}

function DateFormat(date) {
  return format(new Date(date), "MM/dd/yyy");
}

function currencyFormat(num) {
  return "$" + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}
