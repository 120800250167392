const GetISODate = (inputDate) => {
  return StringToDate(inputDate).toISOString();
};

const CheckForAbsentTimeSectionAndAddIfNeeded = (inputString) => {
  if (inputString.includes("T")) {
    return inputString;
  } else {
    return inputString + "T00:00:00";
  }
};

const StringToDate = (inputDate) => {
  //This will ensure we are returning a date object - either it is already a date or it will be converted from a string to a date

  //if inputDate is null or undefined return it
  if (inputDate == null || inputDate == undefined) return inputDate;

  return getDate(inputDate);
};

const ShortDateFormat = (inputDate) => {
  const dt = getDate(inputDate);

  return dt.toLocaleDateString("en-US");
};

function ParseTime(inputTime) {
  if (inputTime == "") return null;

  var time = inputTime.match(/(\d+)(:(\d\d))?\s*(p?)/i);
  if (time == null) return null;

  var hours = parseInt(time[1], 10);
  if (hours == 12 && !time[4]) {
    hours = 0;
  } else {
    hours += hours < 12 && time[4] ? 12 : 0;
  }
  var d = new Date();
  d.setHours(hours);
  d.setMinutes(parseInt(time[3], 10) || 0);
  d.setSeconds(0, 0);
  return d;
}

function DateWithoutTime(inputDate) {
  //If argument already a date just return it

  let dt = getDate(inputDate);

  const result = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate());

  return result;
}

function IsDefaultDate(inputDate) {
  //retun true if the date is the default date before 1900

  let dt = getDate(inputDate);
  return dt.getFullYear() < 1900;
}

function getDate(inputDate) {
  //If argument already a date just return it
  if (isDate(inputDate)) return inputDate;

  /*NOTE: There is a funny case where there's a difference between 
  '2023-03-03' and '2023-03-03T00:00:00'.
  Basically if we do "new Date('2023-03-03')" it will subtract a day from the display because of a timezone issue
  (the first one is "midnight UTC time" and the second one is "midnight local time").
  Therefore we should check to see if the inputDate contains a "T" to indicate time,
  and if then we should append "T00:00:00" so that it doesn't subtract a day.
  OH, additionally if it's '2023-03-03T00:00:00' IS NOT the same as "2023-03-03T00:00:00Z",
  if the first case it reads it as "midnight according to local time" and in the second case "midnight according to UTC time".
  This makes it so that "2023-03-03" is basically "2023-03-03T00:00:00Z".
  */
  let dt = new Date(CheckForAbsentTimeSectionAndAddIfNeeded(inputDate));

  //If argument string not valid initialize it to default date (year 1900)
  if (isNaN(dt)) dt = new Date(0, 0);

  return dt;
}

function IsDataDateType(input) {
  //if input is null or undefined return false
  if (input == null || input == undefined) return false;

  // Parse the date string into a Date object

  try {
    const date = new Date(input);

    // Check if the date is valid
    return !isNaN(date.getTime());
  } catch (error) {
    //if error on date validation return false automatically
    return false;
  }
}

function isDate(input) {
  return typeof input == typeof new Date();
}

function GetDataWithCleanedDates(sourceData, removeTime = true) {
  //this mwethod will take a list of data and will format all items as date which have a string of date in the key
  //if it is not a date format set the value to null
  //it will also provide option to remove the time part of the date - default removeTime param value is true set to false if you want to keep the time part

  let result = sourceData.map((t) => {
    let cleanedData = { ...t };
    for (let key in cleanedData) {
      if (key.toLowerCase().includes("date")) {
        if (IsDataDateType(cleanedData[key])) {
          cleanedData[key] = removeTime
            ? DateWithoutTime(cleanedData[key])
            : new Date(cleanedData[key]);
        } else {
          cleanedData[key] = null;
        }
      }
    }
    return cleanedData;
  });

  return result;
}

//this method should use same logic as last method except it will a list of columns to process and only apply the date formatting to those columns
function GetDataWithCleanedDatesOnSpecifiedColumns(
  sourceData,
  columnsToPrcess,
  removeTime = true
) {
  let result = sourceData.map((t) => {
    let cleanedData = { ...t };
    for (let key in cleanedData) {
      if (
        columnsToPrcess
          .map((col) => col.toLowerCase())
          .includes(key.toLowerCase())
      ) {
        if (IsDataDateType(cleanedData[key])) {
          cleanedData[key] = removeTime
            ? DateWithoutTime(cleanedData[key])
            : new Date(cleanedData[key]);
        } else {
          cleanedData[key] = null;
        }
      }
    }
    return cleanedData;
  });

  return result;
}

export {
  GetISODate,
  StringToDate,
  ShortDateFormat,
  ParseTime,
  DateWithoutTime,
  IsDefaultDate,
  IsDataDateType,
  GetDataWithCleanedDates,
  GetDataWithCleanedDatesOnSpecifiedColumns,
};
