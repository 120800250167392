import axios from "axios";
import Accordion from "../../Accordion";
import React, { useState, useEffect } from "react";
import ErrorHandler from "./../../core/ErrorHandler";
import { LoadingSpinner } from "./../../layout/LoadingSpinner";
import { DetailsModal } from "../../grid/DetailsModal";
import { Row, Col, Form, Container } from "react-bootstrap";
import RxLookupForm from "./RxLookupForm";

import "font-awesome/css/font-awesome.min.css";

import { GridColumn } from "@progress/kendo-react-grid";
import { Button, DropDownButton } from "@progress/kendo-react-buttons";

import { IsNullOrWhitespace } from "../../../helpers/StringHelpers";
import { FormatToCurrency } from "../../../helpers/MoneyHelpers";
import { ShortDateFormat, StringToDate } from "../../../helpers/DateHelpers";

import SharedMainGrid from "../../grid/Kendo/SharedMainGrid";
import {
  MoneyCell,
  AggregateMoneyFooterCell,
} from "../../grid/Kendo/CustomKendoGridCells";
import { ZIndexContext } from "@progress/kendo-react-common";
import { IgnoreModal } from "../SharedComponents/IgnoreModal";
import { IgnoreBody } from "../SharedComponents/IgnoreBody";
import IgnoreOptions from "../SharedComponents/IgnoreOptions";
import { GetDataWithCleanedDates } from "../../../helpers/DateHelpers.js";
import SingleCheckExcelExportWrapper from "../../export/SingleCheckExcelExportWrapper";
import SingleCheckPdfExportWrapper from "../../export/SingleCheckPdfExportWrapper";
import "./RxLookup.css";

import {
  DefaultColumnWidth,
  DefaultDateColumnWidth,
  DefaultBooleanOrShortWidthColumnWidth,
  DefaultNoFilterColumnWidth,
} from "../../grid/Kendo/KendoGridAndColumnConstants";

export default function RxLookup(props) {
  const { passedProps } = props;
  const user = passedProps.user.email;

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});
  const [expanded, setExpanded] = useState(false);

  const [submittedFormData, setSubmittedFormData] = useState(null);
  const [mainGridData, setMainGridData] = useState([]);
  const [
    mainGridMoneyColumnAggregateSettings,
    setMainGridMoneyColumnAggregateSettings,
  ] = useState([]);
  const [mainGridAggregates, setMainGridAggregates] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [detailsModalInputParameters, setDetailsModalInputParameters] =
    useState({});
  const [detailGridCheckData, setDetailGridCheckData] = useState(null);
  const [detailModalClaimData, setDetailModalClaimData] = useState({});
  const [detailError, setDetailError] = useState({});
  const [
    detailGridMoneyColumnAggregateSettings,
    setDetailGridMoneyColumnAggregateSettings,
  ] = useState([]);
  const [detailGridAggregates, setDetailGridAggregates] = useState([]);

  const [showIgnoreModal, setShowIgnoreModal] = useState(false);
  const [showFeesAndAdjustmentModal, setShowFeesAndAdjustmentModal] =
    useState(false);
  const [feesAndAdjustmentData, setFeesAndAdjustmentData] = useState(null);
  const [IgnoreError, setIgnoreError] = useState({});
  const [selectedIgnoreOption, setSelectedIgnoreOption] = useState("");
  const [ignoreNote, setIgnoreNote] = useState("");
  const [selectedNcpdpIgnoreModalText, setSelectedNcpdpIgnoreModalText] =
    useState("");
  const [selectedRxIgnoreModalText, setSelectedRxIgnoreModalText] =
    useState("");

  const _container = React.useRef();
  let defaultColumnWidth = DefaultColumnWidth();

  const sendAggregatesToParent = (aggregatesFromSharedMainAgingGrid) => {
    setMainGridAggregates(aggregatesFromSharedMainAgingGrid.data);
  };

  const sendDetailAggregatesToParent = (aggregatesFromSharedTotalSalesGrid) => {
    setDetailGridAggregates(aggregatesFromSharedTotalSalesGrid.data);
  };

  const sendAccordionParent = (props) => {
    setExpanded(props);
  };

  function handleFormSubmit(formInput) {
    // setIsLoading(true);
    setSubmittedFormData(formInput);
  }

  useEffect(() => {
    // console.log(submittedFormData);
    if (submittedFormData) {
      getMainGridData();
    }
  }, [submittedFormData]);

  function getMainGridData() {
    setIsLoading(true);

    if (submittedFormData) {
      try {
        let accessToken = passedProps.auth.getAccessToken();

        axios
          .get(`api/RxLookup/Get`, {
            params: {
              user: user,
              rxNumber: submittedFormData.rxNumber,
              payers: submittedFormData.payers,
              locations: submittedFormData.locations,
              fromDate: submittedFormData.fromDate,
              toDate: submittedFormData.toDate,
              dateType: submittedFormData.dateType,
              bin: submittedFormData.bin,
              ndc: submittedFormData.ndc,
              patientLastName: submittedFormData.patientLastName,
            },
            headers: { Authorization: `Bearer ${accessToken}` },
          })
          .then(onSuccess)
          .catch(onFailure);
      } catch (exception) {
        onFailure(exception);
      }
    }
  }

  //NOTE TODO: There will be multiple API calls here, we only want to stop the "isLoading" to false after all API calls are completed
  function getDetailsModalData(clid, pyid, rxNumber, mmid) {
    if (clid || pyid) {
      try {
        let accessToken = passedProps.auth.getAccessToken();

        //NOTE: I think this needs to be handled slightly differently
        //because we need both to return before we can display the modal
        axios
          .get(`api/RxLookup/GetCheckDetailsData`, {
            params: {
              user: user,
              clid: clid,
              pyid: pyid,
              mmid: mmid,
              rxNumber: rxNumber,
            },
            headers: { Authorization: `Bearer ${accessToken}` },
          })
          .then(onDetailsModalSuccess)
          .catch(onFailure);

        axios
          .get(`api/RxLookup/GetClaimDetailsData`, {
            params: {
              user: user,
              clid: clid,
              pyid: pyid,
              mmid: mmid,
            },
            headers: { Authorization: `Bearer ${accessToken}` },
          })
          .then(onDetailsModalSuccess)
          .catch(onFailure);
      } catch (exception) {
        onFailure(exception);
      }
    }
  }

  function getFeesAndAdjustmentData(clid, pyid, rxNumber, mmid) {
    if (clid || pyid) {
      try {
        let accessToken = passedProps.auth.getAccessToken();
        axios
          .get(`api/RxLookup/GetClaimLevelFeeAndAdjustmentsData`, {
            params: {
              user: user,
              clid: clid,
              pyid: pyid,
              mmid: mmid,
              rxNumber: rxNumber,
            },
            headers: { Authorization: `Bearer ${accessToken}` },
          })
          .then(onDetailsModalSuccess)
          .catch(onFailure);
      } catch (exception) {
        onFailure(exception);
      }
    }
  }

  function onDetailsModalSuccess(response) {
    switch (response.config.url) {
      case "api/RxLookup/GetClaimDetailsData":
        presentDetailsClaimData(response.data);
        break;
      case "api/RxLookup/GetCheckDetailsData":
        presentDetailsCheckData(response.data);
        break;
      case "api/RxLookup/GetClaimLevelFeeAndAdjustmentsData":
        setFeesAndAdjustmentData(getAllDetailData(response.data));
        break;
      default:
        break;
    }
  }

  const getAllDetailData = (data) => {
    let detailDataRows = [];

    let dateColumnsToApplyDateFormat = [
      "checkDate",
      "fillDate",
      "transactionDate",
    ];

    data.forEach((detail) => {
      //if the checkdate detail item has a value make sure it is a date
      dateColumnsToApplyDateFormat.forEach((column) => {
        if (detail[column])
          detail = { ...detail, [column]: StringToDate(detail[column]) };
      });

      detailDataRows.push(detail);
    });

    //remove all duplicates
    let detailDataRowsUnique = detailDataRows.filter(
      (v, i, a) =>
        a.findIndex(
          (t) =>
            t.checkNumber === v.checkNumber &&
            t.payer === v.payer &&
            t.checkAmount === v.checkAmount &&
            t.checkDate === v.checkDate
        ) === i
    );

    return detailDataRowsUnique;
  };

  useEffect(() => {
    if (detailModalClaimData && detailGridCheckData) {
      setShowModal(true);
      setIsLoading(false);
    }
  }, [detailGridCheckData, detailModalClaimData]);

  function onSuccess(response) {
    switch (response.config.url) {
      case "api/RxLookup/Get":
        presentGridData(response.data);
        break;
      default:
        break;
    }

    setIsLoading(false);
  }

  function onFailure(error) {
    setIsLoading(false);

    switch (error.response.config.url) {
      case "api/RxLookup/Get":
        setError(error);
        break;
      case "api/RxLookup/GetClaimDetailsData":
        setDetailError(error);
        break;
      case "api/RxLookup/GetCheckDetailsData":
        setDetailError(error);
        break;
      case "api/AgingDetails/IgnoreClaims":
        setDetailError(error);
        break;
      default:
        break;
    }

    return;
  }

  function presentGridData(data) {
    if (data.length > 0) {
      //NOTE: Here we set the "date" property to the dispensedDate or the transactionDate,
      //depending on what gets sent over
      // let dataWithCleanedDates = data.map((t) => {
      //   if (!IsNullOrWhitespace(t.dispenseDate)) {
      //     return {
      //       ...t,
      //       dispenseDate: IsNullOrWhitespace(t.dispenseDate)
      //         ? ""
      //         : StringToDate(t.dispenseDate),
      //       transactionDate: IsNullOrWhitespace(t.transactionDate)
      //         ? ""
      //         : StringToDate(t.transactionDate),
      //       ignoreDate: IsNullOrWhitespace(t.ignoreDate)
      //         ? ""
      //         : StringToDate(t.ignoreDate),
      //     };
      //   }
      //   else {
      //     return t;
      //   }
      // });

      let dataWithCleanedDates = GetDataWithCleanedDates(data);

      setError({});
      setMainGridData(dataWithCleanedDates);

      let aggregateSettings = [
        { aggregate: "sum", field: "promiseToPay" },
        { aggregate: "sum", field: "paidAmount" },
      ];

      setMainGridMoneyColumnAggregateSettings(aggregateSettings);
    } else {
      setError({ status: 201, Message: "No data found for given parameters" });
      setMainGridData([]);
    }
  }

  function presentDetailsClaimData(data) {
    if (data.dispenseDate) {
      data.dispenseDate = ShortDateFormat(data.dispenseDate);
    }
    if (data.transactionDate) {
      data.transactionDate = ShortDateFormat(data.transactionDate);
    }
    if (data.ignoreDate) {
      data.ignoreDate = ShortDateFormat(data.ignoreDate);
    }

    setDetailModalClaimData(data);
    setIsLoading(false);
  }

  function presentDetailsCheckData(data) {
    // let dataWithCleanedDates = data.map((t) => {
    //   return {
    //     ...t,
    //     checkDate: IsNullOrWhitespace(t.checkDate)
    //       ? ""
    //       : new Date(Date.parse(t.checkDate)),
    //     checkArchiveDate: IsNullOrWhitespace(t.checkArchiveDate)
    //       ? ""
    //       : new Date(Date.parse(t.checkArchiveDate)),
    //   };
    // });

    let dataWithCleanedDates = GetDataWithCleanedDates(data);

    let aggregateSettings = [{ aggregate: "sum", field: "paid" }];

    setDetailGridMoneyColumnAggregateSettings(aggregateSettings);

    setDetailGridCheckData(dataWithCleanedDates);
  }

  const viewButtonItems = ["Rx Details"];

  const RxLookupActionCell = (props) => {
    async function viewClick(event) {
      setIsLoading(true);

      //NOTE: We're setting this for later retrieval following a claim ignore action
      setDetailsModalInputParameters(props.dataItem);

      getDetailsModalData(
        props.dataItem.clid,
        props.dataItem.pyid,
        props.dataItem.rxNumber,
        props.dataItem.mmid
      );
    }

    return (
      <td className="noWrap" {...props.style}>
        {/* <Button
              onClick={viewClick}
              themeColor={"primary"}
              fillMode={"link"}
            >
              View
            </Button> */}
        <a
          onClick={viewClick}
          style={{
            cursor: "pointer",
            textDecoration: "underline",
            color: "#015CAB",
          }}
        >
          View
        </a>
      </td>
    );
  };

  const MainGridAggregateMoneyFooterCell = (props) => {
    var options = {
      aggregateData: mainGridAggregates,
    };

    return AggregateMoneyFooterCell(props, options);
  };

  let DetailsGridAggregateMoneyCell = (props) => {
    var options = {
      aggregateData: detailGridAggregates,
    };
    return AggregateMoneyFooterCell(props, options);
  };

  function showFeesAndAdjustments() {
    setShowModal(false);
    setShowFeesAndAdjustmentModal(true);
    getFeesAndAdjustmentData(
      detailsModalInputParameters.clid,
      detailsModalInputParameters.pyid,
      detailsModalInputParameters.rxNumber,
      detailsModalInputParameters.mmid
    );
  }

  function addEditIgnoreDetails(event) {
    setShowModal(false);
    setIgnoreNote("");
    setSelectedIgnoreOption("");

    if (!detailModalClaimData.clid) {
      alert("There is no claim data to ignore.");
    } else {
      setSelectedNcpdpIgnoreModalText(detailModalClaimData.ncpdp);
      setSelectedRxIgnoreModalText(detailModalClaimData.rxNumber);
      setShowIgnoreModal(true);
    }
  }

  async function unIgnoreClick() {
    if (window.confirm("Do you want to unignore the claim?")) {
      let accessToken = passedProps.auth.getAccessToken();

      // setIsLoading(true);

      let ignoreParameters = {
        user: user,
        IgnoreId: "0",
        IgnoreNote: "",
        ClaimIds: detailsModalInputParameters.clid,
      };

      setIsLoading(true);
      //NOTE: Need to add a loading spinner here I think
      const response = await axios
        .get("api/AgingDetails/UnIgnoreClaims", {
          params: ignoreParameters,
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then(refetchClaimsDetailsData)
        .catch(onFailure);

      async function refetchClaimsDetailsData(ignoreResponse) {
        await axios
          .get(`api/RxLookup/GetClaimDetailsData`, {
            params: {
              user: user,
              clid: detailsModalInputParameters.clid,
              pyid: detailsModalInputParameters.pyid,
              mmid: detailsModalInputParameters.mmid,
            },
            headers: { Authorization: `Bearer ${accessToken}` },
          })
          .then(onDetailsModalSuccess)
          .catch(onFailure);
      }
    }
  }

  const onIgnoreClaimsSaveButtonCallback = () => {
    setIsLoading(true);
  };

  async function refetchClaimsDetailsData(ignoreResponse) {
    let accessToken = passedProps.auth.getAccessToken();

    await axios
      .get(`api/RxLookup/GetClaimDetailsData`, {
        params: {
          user: user,
          clid: detailsModalInputParameters.clid,
          pyid: detailsModalInputParameters.pyid,
          mmid: detailsModalInputParameters.mmid,
        },
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then(onDetailsModalSuccess)
      .catch(onFailure);
  }

  const onIgnoreClaimSuccessCallback = (response) => {
    setIsLoading(false);
    hideAndResetIgnoreModal();
    refetchClaimsDetailsData(response);
  };

  //Called when user clicks save in ignore details modal
  async function handleSaveIgnoreClick() {
    if (!selectedIgnoreOption) {
      return alert("Please Select an Ignore Code to Save!");
    }

    setIsLoading(true);

    //NOTE: This is where we make the API call to ignore the claim AND then also retriev ethe claim details data again
    let accessToken = passedProps.auth.getAccessToken();

    let ignoreParameters = {
      user: user,
      IgnoreId: selectedIgnoreOption.toString(),
      IgnoreNote: ignoreNote,
      ClaimIds: detailsModalInputParameters.clid,
    };

    async function refetchClaimsDetailsData(ignoreResponse) {
      await axios
        .get(`api/RxLookup/GetClaimDetailsData`, {
          params: {
            user: user,
            clid: detailsModalInputParameters.clid,
            pyid: detailsModalInputParameters.pyid,
            mmid: detailsModalInputParameters.mmid,
          },
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then(onDetailsModalSuccess)
        .catch(onFailure);
    }

    const response = await axios
      .get("api/AgingDetails/IgnoreClaims", {
        params: ignoreParameters,
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .catch(onFailure);

    refetchClaimsDetailsData(response);
  }

  function hideAndResetIgnoreModal() {
    setShowIgnoreModal(false);
    setIgnoreError({});
    setSelectedIgnoreOption("");
    setIgnoreNote("");
    setSelectedNcpdpIgnoreModalText("");
    setSelectedRxIgnoreModalText("");
    setShowModal(true);
  }

  function hideFeesAndAdjustments() {
    setShowFeesAndAdjustmentModal(false);
    setFeesAndAdjustmentData([]);
    setShowModal(true);
  }

  function getReadableIgnoreReason(ignoreReason) {
    try {
      let ignoreOptionOfClaim = IgnoreOptions.find((x) => {
        return x.value == ignoreReason;
        Accordion;
      });

      if (
        ignoreOptionOfClaim != null &&
        ignoreOptionOfClaim.label.trim() == ""
      ) {
        return ignoreReason + "-" + ignoreOptionOfClaim.label;
      } else {
        return ignoreReason;
      }
    } catch (e) {
      console.log(e);
      return ignoreReason;
    }
  }

  const CustomActionCell = (props) => (
    <CustomCell {...props} color={"#015CAB"} />
  );
  const downloadButtonItems = ["Excel", "PDF"];

  const CustomCell = (props) => {
    async function downloadClick(event) {
      const accessToken = await passedProps.auth.getAccessToken();

      if (event.item === "Excel") {
        await SingleCheckExcelExportWrapper(
          props.dataItem.pmid,
          accessToken,
          setIsLoading
        );
      } else if (event.item === "PDF") {
        await SingleCheckPdfExportWrapper(
          props.dataItem.pmid,
          accessToken,
          setIsLoading
        );
      }
    }
    return (
      <td {...props.tdProps} colSpan={1}>
        {" "}
        <ZIndexContext.Provider value={10003}>
          <DropDownButton
            text={
              <span>
                <i className="fa fa-download" aria-hidden></i> Download{" "}
              </span>
            }
            items={downloadButtonItems}
            themeColor={"primary"}
            fillMode={"link"}
            onItemClick={downloadClick}
          />
        </ZIndexContext.Provider>
      </td>
    );
  };

  function handleDetailsModalClose() {
    setShowModal(false);
    getMainGridData();
  }

  return (
    <React.Fragment>
      <div className="feature">
        <LoadingSpinner isDataLoading={isLoading} controlsName={"RxLookup"} />
        <ErrorHandler
          error={error}
          onClose={() => {
            setError({});
          }}
        />
        <Accordion
          defaultExpanded
          label="Search & Filter"
          sendAccordionParentCallback={sendAccordionParent}
        >
          <Row>
            <Col md="auto" className="d-flex flex-column higher-zindex-filters">
              <RxLookupForm handleFormSubmit={handleFormSubmit}></RxLookupForm>
            </Col>
          </Row>
        </Accordion>

        <div ref={_container}>
          <Row>
            <Col className="tight-grid multi-line-filter">
              <br />
              <SharedMainGrid
                styleOverride={{ minHeight: "19em", maxHeight: "50em" }}
                data={mainGridData}
                aggregateColumnSettings={mainGridMoneyColumnAggregateSettings}
                sendAggregatesParentCallback={sendAggregatesToParent}
                dataItemKey={"clid"}
              >
                <GridColumn
                  field=""
                  title="Action"
                  filterable={false}
                  sortable={false}
                  groupable={false}
                  width={DefaultNoFilterColumnWidth()}
                  cells={{ data: RxLookupActionCell }}
                />
                <GridColumn
                  field="rxNumber"
                  title="Rx Number"
                  width={defaultColumnWidth}
                />
                <GridColumn
                  field="ncpdp"
                  title="NCPDP"
                  width={DefaultBooleanOrShortWidthColumnWidth()}
                />
                <GridColumn
                  field="dispenseDate"
                  title="Dispense Date"
                  filter="date"
                  format="{0:d}"
                  width={DefaultDateColumnWidth()}
                />
                <GridColumn
                  field="transactionDate"
                  title="Transaction Date"
                  filter="date"
                  format="{0:d}"
                  width={DefaultDateColumnWidth()}
                />
                <GridColumn
                  field="payerName"
                  title="Payer Name"
                  width={defaultColumnWidth}
                />
                <GridColumn
                  field="promiseToPay"
                  title="Promise To Pay"
                  format="{0:c}"
                  filter="numeric"
                  cells={{
                    data: MoneyCell,
                    footerCell: MainGridAggregateMoneyFooterCell,
                  }}
                  width={defaultColumnWidth}
                />
                <GridColumn
                  field="paidAmount"
                  title="Paid Amount"
                  format="{0:c}"
                  filter="numeric"
                  cells={{
                    data: MoneyCell,
                    footerCell: MainGridAggregateMoneyFooterCell,
                  }}
                  width={defaultColumnWidth}
                />
                <GridColumn
                  field="reconciled"
                  title="Reconciled"
                  width={DefaultBooleanOrShortWidthColumnWidth()}
                />
                <GridColumn
                  field="ignoreFlag"
                  title="Ignore Flag"
                  width={DefaultBooleanOrShortWidthColumnWidth()}
                />

                <GridColumn
                  field="ignoreDate"
                  title="Ignore Date"
                  filter="date"
                  format="{0:d}"
                  width={DefaultDateColumnWidth()}
                />

                <GridColumn
                  field="ignoreCode"
                  title="Ignore Code"
                  width={DefaultDateColumnWidth()}
                />

                <GridColumn
                  field="ignoreReason"
                  title="Ignore Reason"
                  width={DefaultColumnWidth()}
                />
                <GridColumn
                  field="ignoreUser"
                  title="Ignored User"
                  hidden={false}
                  width={0}
                />
              </SharedMainGrid>
            </Col>
          </Row>
        </div>

        <DetailsModal
          title="Rx Details"
          show={showModal}
          handleClose={() => handleDetailsModalClose()}
        >
          <ErrorHandler
            error={detailError}
            onClose={() => {
              setDetailError({});
            }}
          />
          <ZIndexContext.Provider value={10003}>
            <canvas
              id="hiddenCanvasForSingleCheckPdf"
              style={{ display: "none" }}
              width={185}
              height={70}
            />
            <Row className="details-modal-row modal-label">
              <Col md="2" className="d-flex flex-column">
                Rx Number:
              </Col>
              <Col md="2" className="d-flex flex-column">
                NCPDP:
              </Col>
              <Col md="2" className="d-flex flex-column">
                BIN:
              </Col>
              <Col md="2" className="d-flex flex-column">
                PCN:
              </Col>
              <Col md="2" className="d-flex flex-column">
                Payer Name:
              </Col>
              <Col md="2" className="d-flex flex-column">
                Quantity:
              </Col>
            </Row>
            <Row className="details-modal-row">
              <Col md="2" className="d-flex flex-column">
                {detailModalClaimData.rxNumber}
              </Col>
              <Col md="2" className="d-flex flex-column">
                {detailModalClaimData.ncpdp}
              </Col>
              <Col md="2" className="d-flex flex-column">
                {detailModalClaimData.bin}
              </Col>
              <Col md="2" className="d-flex flex-column">
                {detailModalClaimData.pcn}
              </Col>
              <Col md="2" className="d-flex flex-column">
                {detailModalClaimData.payerName}
              </Col>
              <Col md="2" className="d-flex flex-column">
                {detailModalClaimData.quantity}
              </Col>
            </Row>
            <Row className="details-modal-row modal-label">
              <Col md="2" className="d-flex flex-column">
                NDC:
              </Col>
              <Col md="2" className="d-flex flex-column">
                Adjudicated:
              </Col>
              <Col md="2" className="d-flex flex-column">
                Paid:
              </Col>
              <Col md="2" className="d-flex flex-column">
                CoPay:
              </Col>
              <Col md="2" className="d-flex flex-column">
                Dispense Date:
              </Col>
              <Col md="2" className="d-flex flex-column">
                Transaction Date:
              </Col>
            </Row>
            <Row className="details-modal-row">
              <Col md="2" className="d-flex flex-column">
                {detailModalClaimData.ndc}
              </Col>
              <Col md="2" className="d-flex flex-column">
                {FormatToCurrency(detailModalClaimData.adjudicatedAmount)}
              </Col>
              <Col md="2" className="d-flex flex-column">
                {FormatToCurrency(detailModalClaimData.paidAmount)}
              </Col>
              <Col md="2" className="d-flex flex-column">
                {FormatToCurrency(detailModalClaimData.coPay)}
              </Col>
              <Col md="2" className="d-flex flex-column">
                {detailModalClaimData.dispenseDate}
              </Col>
              <Col md="2" className="d-flex flex-column">
                {detailModalClaimData.transactionDate}
              </Col>
            </Row>
            <Row className="details-modal-row modal-label">
              <Col md="2" className="d-flex flex-column">
                Cardholder ID:
              </Col>
              <Col md="2" className="d-flex flex-column">
                Auth Code:
              </Col>
              <Col md="2" className="d-flex flex-column">
                Reconciled:
              </Col>
              <Col md="2" className="d-flex flex-column">
                Follow Ups:
              </Col>
            </Row>
            <Row className="details-modal-row">
              <Col md="2" className="d-flex flex-column">
                {detailModalClaimData.cardHolderId}
              </Col>
              <Col md="2" className="d-flex flex-column">
                {detailModalClaimData.authCode}
              </Col>
              <Col md="2" className="d-flex flex-column">
                {detailModalClaimData.reconciled}
              </Col>
              <Col md="2" className="d-flex flex-column">
                {detailModalClaimData.letter_htm}
              </Col>
            </Row>
            <Row>
              <Col md="4" className="d-flex flex-column">
                <Button
                  onClick={showFeesAndAdjustments}
                  style={{
                    backgroundColor: "#015cab",
                    color: "white",
                  }}
                >
                  Show Claim Level Fees & Adjustments
                </Button>
              </Col>
            </Row>
            <h4>Associated Checks</h4>
            <Row>
              <Col className="tight-grid multi-line-filter">
                <SharedMainGrid
                  data={detailGridCheckData ? detailGridCheckData : []}
                  aggregateColumnSettings={
                    detailGridMoneyColumnAggregateSettings
                  }
                  sendAggregatesParentCallback={sendDetailAggregatesToParent}
                  scrollType="scrollable"
                  styleOverride={{ height: "500px" }}
                >
                  <GridColumn
                    key={"PMID"}
                    field={"PMID"}
                    title={"Download Report"}
                    filterable={false}
                    cells={{
                      data: CustomActionCell,
                    }}
                    width={defaultColumnWidth}
                  />
                  <GridColumn
                    key={"Payer"}
                    field={"payer"}
                    title={"Payer"}
                    width={defaultColumnWidth}
                  />
                  <GridColumn
                    key={"CheckNumber"}
                    field={"checkNumber"}
                    title={"Check Number"}
                    width={defaultColumnWidth}
                  />
                  <GridColumn
                    key={"CheckDate"}
                    field={"checkDate"}
                    title={"Check Date"}
                    width={DefaultDateColumnWidth()}
                    filter={"date"}
                    format="{0:d}"
                  />
                  <GridColumn
                    key={"CheckArchiveDate"}
                    field={"checkArchiveDate"}
                    title={"Check Archive Date"}
                    width={DefaultDateColumnWidth()}
                    filter={"date"}
                    format="{0:d}"
                  />
                  <GridColumn
                    key={"PaidAmount"}
                    field={"paid"}
                    title={"Remittance Paid"}
                    width={defaultColumnWidth}
                    filter={"numeric"}
                    cells={{
                      data: MoneyCell,
                      footerCell: DetailsGridAggregateMoneyCell,
                    }}
                    footerCell={DetailsGridAggregateMoneyCell}
                  />
                  <GridColumn
                    key={"PaymentType"}
                    field={"paymentType"}
                    title={"Payment Type"}
                    width={defaultColumnWidth}
                  />
                </SharedMainGrid>
              </Col>
            </Row>

            <Row>
              <Col md="auto" className="d-flex flex-column">
                <Button
                  onClick={addEditIgnoreDetails}
                  style={{
                    backgroundColor: "#015cab",
                    color: "white",
                  }}
                >
                  Ignore Claim/Edit Ignore Details
                </Button>
              </Col>

              <Col md="auto" className="d-flex flex-column">
                <Button
                  onClick={unIgnoreClick}
                  style={{
                    backgroundColor: "#015cab",
                    color: "white",
                  }}
                >
                  UnIgnore Claim
                </Button>
              </Col>
            </Row>
            <Row>
              <Col md="12" className="d-flex flex-column">
                <div>
                  Is Claim Ignored? &nbsp;
                  <span
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {detailModalClaimData.ignoreFlag === "Y" ? "Yes" : "No"}
                  </span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="12" className="d-flex flex-column">
                Ignored Reason Code:{" "}
                {detailModalClaimData.ignoreReason
                  ? getReadableIgnoreReason(detailModalClaimData.ignoreReason)
                  : ""}
              </Col>
            </Row>
            <Row>
              <Col md="12" className="d-flex flex-column">
                Ignored Claim Note: {detailModalClaimData.ignoreNote}
              </Col>
            </Row>
            <Row>
              <Col md="6" className="d-flex flex-column">
                Ignored User: {detailModalClaimData.ignoreUser}
              </Col>
              <Col md="6" className="d-flex flex-column">
                Ignored Date: {detailModalClaimData.ignoreDate}
              </Col>
            </Row>
          </ZIndexContext.Provider>
        </DetailsModal>

        <IgnoreModal
          title="Ignore From Aging"
          show={showIgnoreModal}
          handleClose={() => hideAndResetIgnoreModal()}
        >
          <ErrorHandler
            error={IgnoreError}
            onClose={() => {
              setIgnoreError({});
            }}
          />

          <IgnoreBody
            selectedNcpdpIgnoreModalText={selectedNcpdpIgnoreModalText}
            selectedRxIgnoreModalText={selectedRxIgnoreModalText}
            handleCancelClick={hideAndResetIgnoreModal}
            selectedClidsToIgnore={detailsModalInputParameters.clid}
            // onSaveButtonClickCallbackFromParent={onIgnoreClaimsSaveButtonCallback}
            onSaveButtonClickCallbackFromParent={
              onIgnoreClaimsSaveButtonCallback
            }
            onSuccessCallbackFromParent={onIgnoreClaimSuccessCallback}
          ></IgnoreBody>

          {/* <Container>
            <Row>
              <Col> NCPDP </Col>
              <Col> Rx #</Col>
            </Row>
            <Row>
              <Col>{selectedNcpdpIgnoreModalText}</Col>
              <Col>{selectedRxIgnoreModalText}</Col>
            </Row>
            <br />
            <Row>
              <Col> Ignore Code </Col>
            </Row>
            <Row md={1}>
              <Col>
                <SingleSelectDropdown
                  options={IgnoreOptions}
                  highlightSelection={true}
                  onChange={(selectedIgnoreOption) => {
                    setSelectedIgnoreOption(selectedIgnoreOption);
                  }}
                />
              </Col>
            </Row>
            <br />
            <Row md={1}>
              <Col>
                <Form.Group>
                  <Form.Label> Comments </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="5"
                    placeholder="Type your message here..."
                    value={ignoreNote}
                    onChange={(event) => {
                      setIgnoreNote(event.target.value);
                    }}
                  >
                    {" "}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <Button
                  variant="secondary"
                  onClick={() => hideAndResetIgnoreModal()}
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  className="primary-button"
                  onClick={() => handleSaveIgnoreClick()}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Container> */}
        </IgnoreModal>

        <DetailsModal
          title="Claim Level Fees & Adjustments"
          show={showFeesAndAdjustmentModal}
          handleClose={() => hideFeesAndAdjustments()}
        >
          <ZIndexContext.Provider value={10004}>
            <Row>
              <Col md="12" className="d-flex flex-column">
                <SharedMainGrid
                  data={feesAndAdjustmentData ? feesAndAdjustmentData : []}
                  dataItemKey="id"
                  aggregateColumnSettings={() => {
                    //do nothing
                  }}
                  sendAggregatesParentCallback={() => {
                    //do nothing
                  }}
                  exportFileNamePrefix={"Fee&AdjustmentDetails"}
                  styleOverride={{ maxHeight: "30em" }}
                >
                  <GridColumn
                    field="payer"
                    title="Payer"
                    width={DefaultColumnWidth()}
                  />
                  <GridColumn
                    field="checkNumber"
                    title="Check Number"
                    width={DefaultColumnWidth()}
                  />
                  <GridColumn
                    key={"ncpdp"}
                    field={"ncpdp"}
                    title={"NCPDP"}
                    filter={"text"}
                    width={DefaultBooleanOrShortWidthColumnWidth()}
                  />

                  <GridColumn
                    key={"checkDate"}
                    field={"checkDate"}
                    title={"Check Date"}
                    filter={"date"}
                    format="{0:d}"
                    width={DefaultDateColumnWidth()}
                  />

                  <GridColumn
                    key={"checkAmount"}
                    field={"checkAmount"}
                    title={"Check Amount"}
                    filter={"text"}
                    width={DefaultColumnWidth()}
                    cells={{
                      data: MoneyCell,
                    }}
                  />
                  <GridColumn
                    key={"rxNumber"}
                    field={"rxNumber"}
                    title={"Rx #"}
                    filter={"text"}
                    width={DefaultColumnWidth()}
                  />
                  <GridColumn
                    key={"fillDate"}
                    field={"fillDate"}
                    title={"Fill Date"}
                    filter={"date"}
                    format="{0:d}"
                    width={DefaultDateColumnWidth()}
                  />
                  <GridColumn
                    key={"transactionDate"}
                    field={"transactionDate"}
                    title={"Transaction Date"}
                    filter={"date"}
                    format="{0:d}"
                    width={DefaultDateColumnWidth()}
                  />
                  <GridColumn
                    key={"patientName"}
                    field={"patientName"}
                    title={"Patient Name"}
                    filter={"text"}
                    width={DefaultColumnWidth()}
                  />
                  <GridColumn
                    key={"feeAdjustmentAmount"}
                    field={"feeAdjustmentAmount"}
                    title={"Fee/Adjustment Amount"}
                    filter={"numeric"}
                    width={DefaultColumnWidth()}
                    cells={{
                      data: MoneyCell,
                    }}
                  />
                  <GridColumn
                    key={"reasonCode"}
                    field={"reasonCode"}
                    title={"Reason Code"}
                    filter={"text"}
                    width={DefaultColumnWidth()}
                  />
                  <GridColumn
                    key={"reasonDescription"}
                    field={"reasonDescription"}
                    title={"Reason Description"}
                    filter={"text"}
                    width={DefaultColumnWidth()}
                  />
                </SharedMainGrid>
              </Col>
            </Row>
          </ZIndexContext.Provider>
        </DetailsModal>
      </div>
    </React.Fragment>
  );
}
