import Accordion from "../../Accordion";
import React, { useState } from "react";
import ErrorHandler from "../../core/ErrorHandler";

import { Row, Col } from "react-bootstrap";
import AdminRxLookupForm from "./AdminRxLookupForm";

import AdminRxLookupDetailPopup from "./AdminRxLookupDetailPopup";

import AdminRxLookupGrid from "./AdminRxLookupGrid";

import "./AdminRxLookupCustomStyles.css";

export default function AdminRxLookup() {
  const [error, setError] = useState({});

  const [setExpanded] = useState(false);
  const [detailError, setDetailError] = useState({});

  const [submittedFormData, setSubmittedFormData] = useState(null);

  const [selectedRxNumber, setSelectedRxNumber] = useState(null);
  const [selectedClaimId, setSelectedClaimId] = useState(null);
  const [selectedPaymentId, setSelectedPaymentId] = useState(null);

  const [showDetailsModal, setShowDetailsModal] = useState(false);

  function handleFormSubmit(formInput) {
    // setIsLoading(true);

    setSubmittedFormData(formInput);
  }

  const sendAccordionParent = (props) => {
    setExpanded(props);
  };

  return (
    <React.Fragment>
      <div className="feature">
        {detailError && (
          <ErrorHandler
            error={error}
            onClose={() => {
              setError({});
            }}
          />
        )}

        <Accordion
          defaultExpanded
          label="Search & Filter"
          sendAccordionParentCallback={sendAccordionParent}
        >
          <Row>
            <Col md="auto" className="d-flex flex-column higher-zindex-filters">
              <AdminRxLookupForm
                handleFormSubmit={handleFormSubmit}
              ></AdminRxLookupForm>
            </Col>
          </Row>
        </Accordion>

        <AdminRxLookupGrid
          setShowDetailsModal={setShowDetailsModal}
          setSelectedRxNumber={setSelectedRxNumber}
          setSelectedClaimId={setSelectedClaimId}
          setSelectedPaymentId={setSelectedPaymentId}
          submittedFormData={submittedFormData}
          setDetailError={setDetailError}
        ></AdminRxLookupGrid>

        <AdminRxLookupDetailPopup
          selectedRxNumber={selectedRxNumber}
          selectedClaimId={selectedClaimId}
          selectedPaymentId={selectedPaymentId}
          show={showDetailsModal}
          onClose={() => {
            setShowDetailsModal(false);
          }}
        ></AdminRxLookupDetailPopup>
      </div>
    </React.Fragment>
  );
}
