import React, { useState, useEffect, useContext } from "react";
import { IgnoreBody } from "../SharedComponents/IgnoreBody";
import { ConstantsContext } from "../../context/ConstantsContext";
import {Modal} from "react-bootstrap";

export default function TestFile(props) {
    const { passedProps } = props;
    const user = passedProps.user;

    let constantsValues = useContext(ConstantsContext);
    console.log(constantsValues);

    const handleCancelClick = () => {
        alert("you have canceled");
    };

    const onApiSuccessCallback = (response) => {
        console.log("hey it completed in useIgnoreClaimApi in TestFile");
        console.log(response);
    }
    //Ntest values
    return (
        <div>
            <IgnoreBody
                selectedNcpdpIgnoreModalText={"test ncpdp"}
                selectedRxIgnoreModalText={"test rx num"}
                handleCancelClick={handleCancelClick}
                selectedClidsToIgnore={50008310834858}
                onSuccessCallbackFromParent={onApiSuccessCallback}
            ></IgnoreBody>
            <Modal show={true}>
                what what see this
            </Modal>
        </div >
    )
}