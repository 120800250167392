import React from "react";
import axios from "axios";
import "./ScreenHeader.css";
import MainLogo from "../../assets/MHA-NetRx-Logo.svg";
import { Col, Navbar } from "react-bootstrap";
import { Organization } from "./Organization";
import { UserAvatar } from "./UserAvatar";
import { Notifications } from "./Notifications";
import { Link } from "react-router-dom";
import { Popover, Overlay, Dropdown } from "react-bootstrap";
import { useState, useRef, useReducer } from "react";
import KendoExcelExporterDirect from "../../export/KendoExcelExporterDirect";
import {
  Base64ToArrayBuffer,
  SaveByteArray,
} from "../../../helpers/Base64ArrayTranslator";

import { useLocation } from "react-router-dom";

export function ScreenHeader(props) {
  const { user, auth } = props;
  const { given_name, family_name } = user;
  const [show, setShow] = useState(false);
  const [hideTutorialOptions, setHideTutorialOptions] = useState(true);
  const target = useRef(null);
  const [directExportState, dispatchDirectExport] = useReducer(
    directExportReducer,
    null
  );

  let isAdminOnlyPage = false;
  let adminOnlyPages = ["/AdminExportBatchQueueMaintenance", "/AdminMissingRemittance", "/AdminRemittanceLookup"];

  //NOTE: Based on the pathname, we can disable the header location
  let location = useLocation();

  if (adminOnlyPages.includes(location.pathname)) {
    isAdminOnlyPage = true;
  }

  function GetProcessedExportDataColumns(sourceColumns) {
    let columns = [];

    if (!sourceColumns) return columns;

    if (sourceColumns.length > 0) {
      columns = sourceColumns.map((value) => {
        let newObject = {
          field: value.accessor,
          title: value.title,
        };

        return newObject;
      });
    }
    return columns;
  }

  function directExportReducer(directExportState, action) {
    let thisAction = action.type.toLowerCase();

    if (thisAction === "clear") {
      return null;
    }

    let thisExportData = null;
    if (action.payload.length > 0) {
      let exportColumns = [];
      let exportRows = [];
      let headerProps = 
      {
        PayerName: { title: 'Payer' },
        Email:{ title: 'Email Address' },
        Phone: { title: 'Phone' },
        Ext: { title: 'Phone Ext'},
        Fax: { title: 'Fax' }
    };

      let thisData = action.payload;

      Object.keys(thisData[0]).forEach((key) => {
        exportColumns.push({
          title: headerProps[key].title,
          accessor: key,
        });
      });

      thisData.forEach((item) => {
        exportRows.push(item);
      });

      //if no exportColumns or exportRows  return null
      if (!exportColumns || exportColumns.length === 0) return null;
      if (!exportRows || exportRows.length === 0) return null;
      thisExportData = {
        columns: exportColumns,
        rows: exportRows,
      };
    }

    return { ExportType: thisAction, ExportData: thisExportData };
  }

  async function getPayerInformation() {
    const accessToken = props.auth.getAccessToken();

    let payerInfo = await axios.get("api/Landing/GetPayerInformation", {
      params: {
        email: user.email,
      },
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return payerInfo.data;
  }

  async function downloadPayerInformation() {
    var payerSource = await getPayerInformation();

    if (payerSource.length > 0) {
      //dispatchDirectExport({ type: "clear" });
      dispatchDirectExport({ type: "excel", payload: payerSource });
    } else {
      alert("No data found for given parameters");
    }
    console.log(payerSource);
  }
  function exportCompletedCallback() {
    dispatchDirectExport({ type: "clear" });
  } 

  async function downloadHelpFile(type) {
    const accessToken = await props.auth.getAccessToken();
    let files = await axios.get("api/UserProgram/GetHelpFiles", {
      params: {
        type: type,
      },
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    var dataAsArray = Base64ToArrayBuffer(files.data.fileStream);
    SaveByteArray(type + ".pdf", dataAsArray);
  }

  //NOTE: By default, Chrome won't open in a new browser window, only a second tab
  const openTutorialVideoInNewWindow = () => {
    window.open(
      "/Tutorial",
      "Remit Admin Tutorial",
      "popup=yes,width=750,height=500"
    );
  };

  const hideOrShowTutorialOptions = (event) => {
    setHideTutorialOptions(!hideTutorialOptions);
  };

  const helpMenuPopover = (
    <Popover className="popover-basic">
      <Dropdown.Item
        eventKey="1"
        onClick={() => downloadHelpFile("Metric-Rx® User Guide")}
      >
        <i className="fa fa-book" /> Metric-Rx® User Guide
      </Dropdown.Item>
      <Dropdown.Item
        eventKey="2"
        onClick={() => downloadHelpFile("RecRx User Guide")}
      >
        <i className="fa fa-book" /> RecRx User Guide
      </Dropdown.Item>
      <Dropdown.Item
        eventKey="3"
        onClick={() => downloadHelpFile("Metric-Rx® Best Practices")}
      >
        <i className="fa fa-bookmark-o" /> Metric-Rx® Best Practices
      </Dropdown.Item>
      <Dropdown.Item
        eventKey="4"
        onClick={() => downloadHelpFile("RecRx Best Practices")}
      >
        <i className="fa fa-bookmark-o" /> RecRx Best Practices
      </Dropdown.Item>
      <Dropdown.Item
        eventKey="5"
        onClick={() => downloadHelpFile("Metric-Rx® FAQ")}
      >
        <i className="fa fa-question-circle-o" /> Metric-Rx® FAQ
      </Dropdown.Item>
      <Dropdown.Item eventKey="6" onClick={() => downloadHelpFile("RecRx FAQ")}>
        <i className="fa fa-question-circle-o" /> RecRx FAQ
      </Dropdown.Item>
      <Dropdown.Item eventKey="11" onClick={() => downloadPayerInformation()}>
        <i className="fa fa-question-circle-o" /> Payer Contact Information
      </Dropdown.Item>
      <Dropdown.Item eventKey="7" onClick={() => downloadHelpFile("Release Notes")}>
        <i className="fa fa-book" /> Release Notes
      </Dropdown.Item>
      <Dropdown.Item eventKey="8" onClick={(event) => hideOrShowTutorialOptions(event)}>
        <i className="fa fa-question-circle-o" /> Video Tutorials
      </Dropdown.Item>
      <Dropdown.Item eventKey="9" hidden={hideTutorialOptions} onClick={() => openTutorialVideoInNewWindow()}>
        <i style={{ paddingLeft: '2em' }} className="fa fa-question-circle-o" /> -RecRx
      </Dropdown.Item>
      <Dropdown.Item eventKey="10" hidden={hideTutorialOptions} onClick={() => openTutorialVideoInNewWindow()}>
        <i style={{ paddingLeft: '2em' }} className="fa fa-question-circle-o" /> -Metric-Rx
      </Dropdown.Item>
    </Popover>
  );

  return (
    <Navbar className="no-gutters" expand="lg" id="screen-header">
      <Col className="sm-4 no-gutters">
        <Link to="/">
          <Navbar.Brand>
            <img src={MainLogo} width="210" height="80" alt="logo" />
          </Navbar.Brand>
        </Link>
      </Col>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse className="no-gutters" id="basic-navbar-nav">
        <Col
          className="no-gutters action-link-with-icon"
          id="organization-action-link"
        >
          {isAdminOnlyPage ? null : <Organization />}
        </Col>
        <Col className="no-gutters" id="screen-header-action-link-group">
          <span
            className="action-link-with-icon"
          >
            <span onClick={() => setShow(!show)}>
              <i ref={target} className="fa fa-info-circle" />
              Help Center
            </span>
            <Overlay
              target={target.current}
              show={show}
              placement="bottom"
              rootClose
              onHide={() => setShow(!show)}
            >
              {helpMenuPopover}
            </Overlay>
          </span>
        </Col>
        {/* <Col className="col-md-auto">
          <Notifications
            user={{ firstName: given_name, lastName: family_name }}
            auth={auth}
          ></Notifications>
        </Col> */}
        <UserAvatar
          user={{ firstName: given_name, lastName: family_name }}
          auth={auth}
        />
       
      </Navbar.Collapse>
      {directExportState && directExportState.ExportType == "excel" && (
          <KendoExcelExporterDirect
            data={directExportState.ExportData.rows}
            columns={GetProcessedExportDataColumns(
              directExportState.ExportData.columns
            )}
            exportCompletedCallback={exportCompletedCallback}
            exportFileNamePrefix={"PayerContactInformation"}
          ></KendoExcelExporterDirect>)
        }
    </Navbar>
  );
}
