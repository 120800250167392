import React, { useState, useEffect } from 'react';
import { Row, Col, NavDropdown, Nav} from 'react-bootstrap';
import { DetailsModal } from '../../grid/DetailsModal';
import ErrorHandler from './../../core/ErrorHandler';
import axios from 'axios';
import { constants } from './PaymentExceptionConstants'
import { getter, ZIndexContext } from "@progress/kendo-react-common";
import { RowExcelExporter } from '../../export/RowExcelExporter'
import { LoadingSpinner } from '../../layout/LoadingSpinner';
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { DropDownButton } from "@progress/kendo-react-buttons";
import { process} from "@progress/kendo-data-query";
import SingleCheckExcelExportWrapper from "../../export/SingleCheckExcelExportWrapper";
import SingleCheckPdfExportWrapper from "../../export/SingleCheckPdfExportWrapper";

export default function PrescriptionDetailsModal(props) {
    const { auth, clid, show, handleClose, handleCellClick, user } = props;
    const [scriptDetailData, setScriptDetailData] = useState([])
    const [scriptDetailColumns, setScriptDetailColumns] = useState([])
    const [checkDetailData, setCheckDetailData] = useState([])
    const [checkDetailColumns, setCheckDetailColumns] = useState([])
    const [detailError, setDetailError] = useState({})

    const [excelData, setExcelData] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const INITIAL_GRID_DATA_STATE = { skip: 0, take: 10 };
    const [checkDetailsGridDataState, setCheckDetailsGridDataState] = useState(INITIAL_GRID_DATA_STATE);
    const [checkDetailsGridResultState, setCheckDetailsGridResultState] = useState(checkDetailData);

    const onGridDataStateChange = (event) => {
        let processedData = process(checkDetailData, event.dataState);
        setCheckDetailsGridDataState(event.dataState);
        setCheckDetailsGridResultState(processedData);
    };
    
      //NOTE: If the details modal grid data changes, then this is what kicks in
      useEffect(() => {
         let processedData = process(checkDetailData, checkDetailsGridDataState);
        setCheckDetailsGridDataState(INITIAL_GRID_DATA_STATE);
        setCheckDetailsGridResultState(processedData);
      }, [checkDetailData]);

    const CustomActionCell = (props) => <CustomCell {...props} color={"#015CAB"}  />;
    const downloadButtonItems = ["Excel", "PDF"];

    const CustomCell = (props) => {
        async function downloadClick(event) {
            const accessToken =  await auth.getAccessToken();
            
            if (event.item === "Excel") {
              await SingleCheckExcelExportWrapper(props.dataItem.pmid, accessToken, setIsLoading);
            }
            else if (event.item === "PDF") {
              await SingleCheckPdfExportWrapper(props.dataItem.pmid, accessToken, setIsLoading);
            }
          }
        return (
            <td
                {...props.tdProps}
                colSpan={1}
            > <ZIndexContext.Provider value={10003}>
                    <DropDownButton
                        text={<span><i className='fa fa-download' aria-hidden></i> Download </span>}
                        items={downloadButtonItems}
                        themeColor={"primary"}
                        fillMode={"link"}
                        onItemClick={downloadClick}
                    />
                </ZIndexContext.Provider>
            </td>
        );
    };

    useEffect(() => {
        if (clid) {
            try {
                const accessToken = auth.getAccessToken();
                let response = axios.get(`api/PaymentException/GetScriptDetails`, {
                    params: {
                        user: user,
                        clid: clid
                    },
                    headers: { Authorization: `Bearer ${accessToken}` }
                })
                    .then(setScriptData)
                    .catch(onFailure)
            }
            catch (exception) {
                onFailure(exception);
            }
        }
    }, [clid])

    useEffect(() => {
        if (clid && scriptDetailData) {
            try {
                const accessToken = auth.getAccessToken();
                let response = axios.get(`api/PaymentException/GetCheckDetails`, {
                    params: {
                        user: user,
                        clid: clid
                    },
                    headers: { Authorization: `Bearer ${accessToken}` }
                })
                    .then(setGrid)
                    .catch(onFailure)

            }
            catch (exception) {
                onFailure(exception);
            }
        }
    }, [scriptDetailData])

    function setScriptData(response) {
        const scriptDetailColumns = []
        const scriptDetailheaderProps = constants.scriptDetailheaderProps

        Object.keys(response.data[0]).map(key => {
            return scriptDetailColumns.push({
                accessor: key,
                title: scriptDetailheaderProps[key].title,
                type: scriptDetailheaderProps[key].type,
                showTotal: scriptDetailheaderProps[key].showTotal,
            })
        })

        setScriptDetailData(response.data)
        setScriptDetailColumns(scriptDetailColumns)
    }

    function setGrid(response) {
        let checkDetailRows = response.data
        const checkDetailColumns = []
        if (response.data && checkDetailRows.length  && scriptDetailData.length) {
            const checkDetailheaderProps = constants.checkDetailheaderProps
            checkDetailColumns.push({ accessor: "Action", title: "Download Report", type: "custom", fixedLeft: true });

            Object.keys(response.data[0]).map(key => {
                return checkDetailColumns.push({
                    accessor: key,
                    title: checkDetailheaderProps[key].title,
                    type: checkDetailheaderProps[key].type,
                    showTotal: checkDetailheaderProps[key].showTotal,
                    hidden: checkDetailheaderProps[key].hidden
                })
            })

            setCheckDetailColumns(checkDetailColumns)
            setCheckDetailData(checkDetailRows)
            setDetailError({})
        }
        else {
            setDetailError({ status: 201, Message: 'No data found for given parameters' })
        }
    }

    function onFailure(error) {
        setDetailError(error);
        setCheckDetailColumns([])
        setScriptDetailColumns([])
        setCheckDetailData([])
        setScriptDetailData([])
    }


    return (
        <DetailsModal title="Prescription Detail" show={show} handleClose={handleClose}>
            <canvas id="hiddenCanvasForSingleCheckPdf" style={{ display: "none" }} width={185} height={70} />
            <LoadingSpinner isDataLoading={isLoading}/> 
            <RowExcelExporter dataSet={excelData} reset={() => setExcelData(null)} filename='Remittance Lookup'/>
            <ErrorHandler error={detailError} onClose={() => { setDetailError({}) }} />
            <Row>
                <Col>
                    <Grid
                        data={scriptDetailData}
                        sortable={true}
                    >
                        <GridColumn key={"bin"} field={"bin"} title={"BIN"} width={"150em"} />

                        <GridColumn key={"pcn"} field={"pcn"} title={"PCN"} width={"150em"} />
                        <GridColumn key={"ndc"} field={"ndc"} title={"NDC"}  width={"150em"}/>
                        <GridColumn key={"followUps"} field={"followUps"} title={"Follow Ups"}  width={"150em"}/>
                        <GridColumn key={"authCode"} field={"authCode"} title={"AuthCode"} width={"150em"} />
                        <GridColumn key={"cardholderID"} field={"cardholderID"} title={"Cardholder ID"} width={"150em"} />
                        <GridColumn key={"quantity"} field={"quantity"} title={"Quantity"} width={"150em"} />
                        <GridColumn key={"reconciled"} field={"reconciled"} title={"Reconciled"} width={"150em"} />
                    </Grid>
                </Col>
                <Col />
            </Row>
            <br />
            <h4>Associated Checks</h4>
            <Grid
                data={checkDetailsGridResultState}
                sortable={true}
                pageable={true}
                // skip={INITIAL_GRID_DATA_STATE.skip}
                // take={INITIAL_GRID_DATA_STATE.take}
                onDataStateChange={onGridDataStateChange}
                {...checkDetailsGridDataState}
            >
                <GridColumn key={"PMID"} field={"PMID"} title={"Download Report "} filterable={false} cells={{
                    data: CustomActionCell,
                }} width={"220px"} />
                <GridColumn key={"payer"} field={"payer"} title={"Payer"} width={"200px"} />
                <GridColumn key={"checkNumber"} field={"checkNumber"} title={"Check Number"} width={"150px"} />
                <GridColumn key={"paid"} field={"paid"} title={"Paid"} width={"150px"} />
                <GridColumn key={"paymentType"} field={"paymentType"} title={"Payment Type"} width={"250px"} />
            </Grid>  
        </DetailsModal>
    )
}