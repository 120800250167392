import "bootstrap/dist/css/bootstrap.css";

import "./styles/css/rmeCustom.css";
import "./styles/css/rmeCustomGridStyles.css";
import "font-awesome/css/font-awesome.min.css";

import "./styles/themes/metric2theme/dist/scss/index.scss";

import React from "react";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import App from "./App";
import { registerServiceWorker, unregister } from "./registerServiceWorker";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");

const root = createRoot(rootElement); // createRoot(container!) if you use TypeScript
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 1000 * 60 * 7, // 7 minutes - garbage collection time
      staleTime: 1000 * 60 * 5, // 5 minutes - data considered stale (for silent refetch)
    },
  },
});

root.render(
  <BrowserRouter basename={baseUrl}>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </BrowserRouter>
);

//ReactDOM.render(
//  <BrowserRouter basename={baseUrl}>
//    <App />
//  </BrowserRouter>,
//  rootElement);

//We are encountering unwanted caching issues, so to address it se will unregister the service worker by calling the unregister method.we will also remove the service worker from the browser by calling the unregister method. This will remove the service worker from the browser and will not cache the files.
//registerServiceWorker();
unregister();
